import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { format } from 'date-fns';
import { frCA } from 'date-fns/locale';
import englishUSA from './en-us.json';
import frenchCAN from './fr-ca.json';
import englishCA from './en-ca.json';

// the translations
const resourceContent = {
  'en-US': {
    translation: englishUSA,
  },
  'en-CA': {
    translation: englishCA,
  },
  'fr-CA': {
    translation: frenchCAN,
  },
};

i18n
  // passes i18n down to react-i18next
  .use(initReactI18next)
  .init({
    interpolation: {
      // React already does escaping
      format: (value, formatedDate, lng) => {
        if (value instanceof Date && lng === 'fr-CA') {
          return format(value, formatedDate || 'dd MMM yyyy', { locale: frCA });
        }
        if (value instanceof Date && lng !== 'fr-CA') {
          return format(value, formatedDate || 'MMM dd, yyyy');
        }
        return value;
      },
      escapeValue: false,
    },
    // 'en' | 'es'
    lng: i18n.language,
    fallbackLng: 'en-US',
    resources: resourceContent,
    keySeparator: true,
  });

export default i18n;
