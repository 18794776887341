/* istanbul ignore file */
/* eslint security/detect-object-injection: 0 */
/* eslint security/detect-unsafe-regex: 0 */
import queryString from 'query-string';
import jwtDecode from 'jwt-decode';
import { formatInTimeZone } from 'date-fns-tz';
import i18n from '../localization/i18n';
import * as rk from '../localization/resourceKeys';
import globalConstants from '../constants/globalConstants';
import { get, set } from '../services/session-service';
import * as RoutePaths from '../RoutePaths';
import { MessageTypes } from '../constants/componentConstants';

/**
 * Get the languageCode from props
 *
 * @returns {undefined|*}
 */
export const getLanguageCode = () => {
  const appProps = get('appProps');
  if (appProps) {
    return JSON.parse(appProps).languageCode;
  }
  return undefined;
};

/**
 * Regular expression to format a number with commas and 2 decimal places
 * @param num the number to format
 * @returns {string}
 */
export const currencyFormat = (num = 0, languageCode = 'en-US') => {
  const isNegative = num < 0;
  const isString = typeof num === 'string';
  const absoluteNumber = isString ? num.replace('-', '').replace(/,/g, '') : Math.abs(num);
  const numberFormat = Intl.NumberFormat(languageCode, {
    style: 'currency',
    currency: 'USD',
    useGrouping: true,
  });
  const formattedNumber =
    languageCode === globalConstants.FR_CA
      ? numberFormat.format(absoluteNumber).replace('US', '')
      : `$${parseFloat(absoluteNumber)
          .toFixed(2)
          .replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}`;
  return `${isNegative ? '-' : ''}${formattedNumber}`;
};

export const percentageFormat = (num = 0, languageCode = 'en-US') => {
  const isString = typeof num === 'string';
  let absoluteNumber = isString ? num.replace('-', '').replace(/,/g, '') : Math.abs(num);
  absoluteNumber /= 100;
  const numberFormat = Intl.NumberFormat(languageCode, {
    style: 'percent',
    minimumFractionDigits: 2,
  });
  return languageCode !== globalConstants.FR_CA
    ? numberFormat.format(absoluteNumber).replace('%', ' %')
    : numberFormat.format(absoluteNumber);
};

/**
 * Format date to 'July 3rd'
 * @param date the Date to format
 * @returns {string}
 */
export const formatDate = (date, returnDayOnly) => {
  let formatted;
  if (date) {
    const dt = new Date(date);

    const monthNames = i18n.t(rk.UTILITIES_MONTHS, { returnObjects: true });

    /* eslint-disable */
    const getDate =
      dt.getDate() +
      (dt.getDate() % 10 === 1 && dt.getDate() !== 11
        ? 'st'
        : dt.getDate() % 10 === 2 && dt.getDate() !== 12
        ? 'nd'
        : dt.getDate() % 10 === 3 && dt.getDate() !== 13
        ? 'rd'
        : 'th');
    /* eslint-enable */

    const getMonth = monthNames[dt.getMonth()];
    formatted = `${getMonth} ${getDate}`;
    if (returnDayOnly) {
      return getDate;
    }
  }
  return formatted;
};

/**
 * format date to work in Firefox
 * @param date
 * @returns formatted date
 */

export const reformatDate = (date) => {
  return date.replaceAll('-', '/');
};

/** Export date in format MM/DD/YYYY
 * @param d the date object
 * @returns {string|null} the formatted string in MM/DD?YYYY format or DD/MM/YYYY for french
 */
export const dateFormat = (date, languageCode) => {
  // hack to catch both date being Date object or a string.
  let dateWithoutZulu;
  if (date instanceof Date) {
    const dateString = date.toISOString();
    dateWithoutZulu = dateString?.slice(-1) === 'Z' ? dateString.slice(0, -1) : dateString;
  } else {
    dateWithoutZulu = date?.slice(-1) === 'Z' ? date.slice(0, -1) : date;
  }
  let d;
  if (languageCode === 'fr-CA') {
    d = new Date(Date.parse(dateWithoutZulu));
    return `${`0${d.getDate()}`.slice(-2)}/${`0${d.getMonth() + 1}`.slice(-2)}/${d.getFullYear()}`;
  }
  d = new Date(dateWithoutZulu);
  return `${`0${d.getMonth() + 1}`.slice(-2)}/${`0${d.getDate()}`.slice(-2)}/${d.getFullYear()}`;
};

/**
 * @returns current date and time in Central time zone
 */
export const getCentralTimeToday = (format) => {
  const now = new Date();
  const centralTimeZone = 'America/Chicago';
  const centralTimeToday = formatInTimeZone(now, centralTimeZone, format ?? 'yyyy-MM-dd');
  return centralTimeToday;
};

/** Export date in format MM/DD/YYYY or return null if date is not valid
 * @param d the date object
 * @returns {string | null} the formatted string in MM/DD?YYYY format or DD/MM/YYYY for french
 */
export const dateFormatted = (date, languageCode) => {
  return date ? dateFormat(date, languageCode) : null;
};

/** Return null if value is not valid
 * @param value the object
 * @returns {string | null} the valid value or null
 */
export const valueFormatted = (value) => {
  return value || null;
};

/**
 * Show the last digits of a number
 *
 * @param number the number to show
 * @param length the number of digits to show
 * @returns {string}
 */
export const lastDigits = (number, length = 0) => {
  if (!!number === false) return '';
  const s = `${number}`;
  return s.length > 4 ? s.substr(s.length - length) : s;
};

/**
 * Read the last digits of a number
 *
 * @param number the number to show
 * @param length the number of digits to show
 * @returns {string}
 */
export const readLastDigits = (s) => {
  return s.split('').join(' ');
};

/**
 * Get param from a url
 *
 * @param paramName name of param to fetch
 * @returns {string[] | string | null | undefined}
 */
export const getUrlParam = (paramName) => {
  const params = queryString.parse(window.location.search);
  return params[paramName];
};

/**
 * Get brand from valid values
 *
 * @param value the value to check
 * @param validValues array of valid brands
 * @returns {*} brand or null if invalid
 */
export const getBrandFromValues = (value, validValues) => {
  return validValues.find((element) => element === value);
};

export const generateRestrictKeyPressHandler = (regex) => {
  return (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    const stringCode = String.fromCharCode(charCode);
    const resultantValue = e.target.value + stringCode;

    if (e.target.selectionStart === 0 && e.target.selectionEnd === e.target.value.length) {
      return true;
    }
    if (!regex.test(resultantValue)) {
      return e.preventDefault();
    }

    return true;
  };
};

export const isMocking = () => {
  const isMockFn = () => process.env.REACT_APP_ENV === 'mock';
  const isRecordFn = () => process.env.REACT_APP_ENV === 'record';
  return isMockFn() || isRecordFn();
};

/**
 * Create a uuid4
 *
 * @returns {string} the uuid
 */
/* eslint  no-bitwise: 0 */
export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

/**
 * Get the correlationId from props
 *
 * @returns {string} the correlationId
 */
export const getCorrelationId = () => {
  const appProps = get('appProps');
  if (appProps) {
    return JSON.parse(appProps).correlationId;
  }
  return undefined;
};

/**
 * Get the brand from props
 *
 * @returns {undefined|*}
 */
export const getBrand = () => {
  const appProps = get('appProps');
  if (appProps) {
    return JSON.parse(appProps).brand;
  }
  return undefined;
};

/**
 * Get the country from props
 *
 * @returns {undefined|*}
 */
export const getCountry = () => {
  const appProps = get('appProps');
  if (appProps) {
    return JSON.parse(appProps).country;
  }
  return undefined;
};

/**
 * Get the Vin from appProps
 *
 * @returns {undefined|*}
 */
export const getVin = () => {
  const appProps = get('appProps');
  if (appProps) {
    return JSON.parse(appProps).vin;
  }
  return undefined;
};

/**
 * Get the account number from authprops
 * @returns {undefined|*}
 */
export const getAccountNumber = () => {
  const appProps = get('appProps');
  let accountNumber;
  if (appProps) {
    accountNumber = JSON.parse(appProps).accountNumber;
  }
  // if not found in appProps, then use authProps
  const authProps = get('authProps');
  if (authProps) {
    accountNumber = accountNumber || JSON.parse(authProps).accountNumber;
  }
  return accountNumber;
};

/**
 * Get the parentdomain from props
 *
 * @returns {string} the parentDomain | bnull
 */
export const getParentDomain = () => {
  const appProps = get('appProps');
  if (appProps) {
    return JSON.parse(appProps).parentDomain;
  }
  return undefined;
};

/**
 * Get the handleEvent function from app props
 *
 * @returns {undefined|*}
 */
export const getEventHandler = () => {
  return window.xprops ? window.xprops.handleEvent : undefined;
};

/**
 * Get partyId from auth properties
 *
 * @returns {number|string|undefined}
 */
export const getPartyId = () => {
  const authProps = get('authProps');
  if (authProps) {
    return JSON.parse(authProps).partyId;
  }
  return undefined;
};

/**
 * Get externalId passed to component
 *
 * @returns {*}
 */
export const getExternalId = () => {
  return window.xprops ? window.xprops.externalId : null;
};

/**
 * Get sourceId passed to component
 *
 * @returns {*}
 */
export const getSourceId = () => {
  const appProps = get('appProps');
  if (appProps) {
    return JSON.parse(appProps).source;
  }
  return undefined;
};

/**
 * Is this a VW brand experience
 * @param brand string | undefined of the brand
 */
export const isVWBrand = (brand) => {
  return brand === globalConstants.VW_BRAND;
};

/**
 * get token expiration from toke in auth props
 * @returns {number|undefined}
 */
export const getTokenExpTime = () => {
  const authProps = JSON.parse(get('authProps'));
  if (authProps?.token) {
    const { exp } = jwtDecode(authProps?.token);
    return new Date(exp * 1000);
  }
  return undefined;
};

/**
 * Redact sensitive data
 *
 * @param source the source json as a string
 * @param patterns an array of key names to redact in json
 */
export const redactOnJson = (source, patterns) => {
  let newString = source.slice();
  patterns.forEach((pattern) => {
    const keyStartIndex = newString.indexOf(pattern);
    const valueStartIndex = keyStartIndex + pattern.length + 5;
    const valueEndIndex = newString.indexOf('\\"', valueStartIndex);
    const replaceValue = newString.substring(valueStartIndex, valueEndIndex);
    const matchPattern = `\\"${pattern}\\":\\"${replaceValue}\\"`;
    const replacement = `\\"${pattern}\\":\\"***\\"`;
    newString = newString.replace(matchPattern, replacement);
  });
  return newString;
};

export const getTelephone = (brand) => {
  const brandLowerCase = brand?.toLowerCase();

  switch (brandLowerCase) {
    case 'audi':
      return globalConstants.AUDI_TELEPHONE;
    case 'vw':
      return globalConstants.VW_TELEPHONE;
    default:
      return globalConstants.AUDI_TELEPHONE;
  }
};

/**
 * Formats address
 *
 * @param {string | number} value
 */
export const formatAddress = (value) => {
  if (value) {
    return value.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  }
  return '';
};

/**
 * Post a message to event handler method if it exists, otherwise use standard window.postmessage
 *
 * @param parentDomain: string | null - target parent domain
 * @param type: string - the type of message
 * @param data: any - the data to pass
 */
export const postMessage = (parentDomain, type, data) => {
  console.info(`postMessage`, {
    parentDomain,
    source: globalConstants.SOURCE_ID,
    correlationId: getCorrelationId(),
    type,
    data,
  });
  const handler = getEventHandler();
  if (handler) {
    handler(type, globalConstants.SOURCE_ID, data);
    return;
  }
  if (parentDomain) {
    window.parent.postMessage(
      {
        type,
        source: globalConstants.SOURCE_ID,
        correlationId: getCorrelationId(),
        data,
      },
      parentDomain
    );
  }
};

/**
 * Change route, log it and potentially business event depending on hash path
 *
 * @param location
 */
export const changeRoute = (location) => {
  const data = {
    accountNumber: getAccountNumber(),
  };
  if (location?.hash) {
    const path = `${location.pathname}${location.hash}`;
    console.debug(`Location changed to ====> ${path}`);
    // only log /dashboard to info for spunk
    // TOBEDONE - we need to change what spunk is looking for....
    if (path === RoutePaths.DASHBOARD_PATH) {
      console.debug(`Location changed to ====> ${path}`);
    }
    switch (location.hash) {
      case RoutePaths.MAKE_PAYMENT_DIALOG_PATH:
        postMessage(getParentDomain(), MessageTypes.PAYMENT_STARTED, data);
        break;
      case RoutePaths.AUTOPAY_ENROLL_DIALOG_PATH:
        postMessage(getParentDomain(), MessageTypes.AUTOPAY_ENROLL_STARTED, data);
        break;
      case RoutePaths.PAPERLESS_ENROLL_DIALOG_PATH:
        postMessage(getParentDomain(), MessageTypes.PAPERLESS_ENROLL, data);
        break;
      case RoutePaths.AUTOPAY_CANCEL_DIALOG_PATH:
        postMessage(getParentDomain(), MessageTypes.AUTOPAY_CANCEL_STARTED, data);
        break;
      case RoutePaths.PAYOFF_QUOTE_DIALOG_PATH:
        postMessage(getParentDomain(), MessageTypes.PAYOFF_QUOTE_STARTED, data);
        break;
      case RoutePaths.CANCEL_PAYMENT_DIALOG_PATH:
        postMessage(getParentDomain(), MessageTypes.CANCEL_PAYMENT_STARTED);
        break;
      case RoutePaths.LEASE_TIMELINE_PATH:
        postMessage(getParentDomain(), MessageTypes.LEASE_TIMELINE_STARTED, data);
        break;
      case RoutePaths.LEASE_TRANSITION_PATH:
        postMessage(getParentDomain(), MessageTypes.LEASE_TRANSITION_STARTED, data);
        break;
      default:
        break;
    }
  } else {
    console.debug(`Location changed to ====> ${location.pathname}`);
  }
};

/**
 * Prevent background scroll on modal open
 *
 * @param disableScroll boolean flag to enable or disable scroll
 */
export const disableBackgroundScroll = (disableScroll) => {
  const el = document.getElementsByTagName('BODY')[0];
  if (disableScroll) {
    el.className = `${el.className} scroll-disable`;
    return;
  }
  el.classList.remove('scroll-disable');
};

export const tooltipIcon = (brand) => {
  if (isVWBrand(brand)) return 'c-icon--[info] tooltip-icon';
  return 'c-icon--[semantic-info] c-icon--xsmall u-pl-small u-mt-xsmall';
};
/**
 * Validate if zip code is mathcing with the entered state
 *
 * @param zipString zip code value in string
 * @param stateString state value in string
 * @returns true
 */
// TOBEDONE - Refactor this function to reduce its Cognitive Complexity
// eslint-disable-next-line
export const isZipMatchedToState = (zipString, stateString) => {//NOSONAR
  /* Ensure we don't parse strings starting with 0 as octal values */
  const zipcode = parseInt(zipString, 10);
  let st;
  /* Code cases alphabetized by state */
  if (zipcode >= 35000 && zipcode <= 36999) {
    st = 'AL';
    // 'Alabama';
  } else if (zipcode >= 99500 && zipcode <= 99999) {
    st = 'AK';
    // 'Alaska';
  } else if (zipcode >= 85000 && zipcode <= 86999) {
    st = 'AZ';
    // 'Arizona';
  } else if (zipcode >= 71600 && zipcode <= 72999) {
    st = 'AR';
    // 'Arkansas';
  } else if (zipcode >= 90000 && zipcode <= 96699) {
    st = 'CA';
    // 'California';
  } else if (zipcode >= 80000 && zipcode <= 81999) {
    st = 'CO';
    // 'Colorado';
  } else if ((zipcode >= 6000 && zipcode <= 6389) || (zipcode >= 6391 && zipcode <= 6999)) {
    st = 'CT';
    // 'Connecticut';
  } else if (zipcode >= 19700 && zipcode <= 19999) {
    st = 'DE';
    // 'Delaware';
  } else if (zipcode >= 32000 && zipcode <= 34999) {
    st = 'FL';
    // 'Florida';
  } else if ((zipcode >= 30000 && zipcode <= 31999) || (zipcode >= 39800 && zipcode <= 39999)) {
    st = 'GA';
    // 'Georgia';
  } else if (zipcode >= 96700 && zipcode <= 96999) {
    st = 'HI';
    // 'Hawaii';
  } else if (zipcode >= 83200 && zipcode <= 83999) {
    st = 'ID';
    // 'Idaho';
  } else if (zipcode >= 60000 && zipcode <= 62999) {
    st = 'IL';
    // 'Illinois';
  } else if (zipcode >= 46000 && zipcode <= 47999) {
    st = 'IN';
    // 'Indiana';
  } else if (zipcode >= 50000 && zipcode <= 52999) {
    st = 'IA';
    // 'Iowa';
  } else if (zipcode >= 66000 && zipcode <= 67999) {
    st = 'KS';
    // 'Kansas';
  } else if (zipcode >= 40000 && zipcode <= 42999) {
    st = 'KY';
    // 'Kentucky';
  } else if (zipcode >= 70000 && zipcode <= 71599) {
    st = 'LA';
    // 'Louisiana';
  } else if (zipcode >= 3900 && zipcode <= 4999) {
    st = 'ME';
    // 'Maine';
  } else if (zipcode >= 20600 && zipcode <= 21999) {
    st = 'MD';
    // 'Maryland';
  } else if ((zipcode >= 1000 && zipcode <= 2799) || zipcode === 5501 || zipcode === 5544) {
    st = 'MA';
    // 'Massachusetts';
  } else if (zipcode >= 48000 && zipcode <= 49999) {
    st = 'MI';
    // 'Michigan';
  } else if (zipcode >= 55000 && zipcode <= 56899) {
    st = 'MN';
    // 'Minnesota';
  } else if (zipcode >= 38600 && zipcode <= 39999) {
    st = 'MS';
    // 'Mississippi';
  } else if (zipcode >= 63000 && zipcode <= 65999) {
    st = 'MO';
    // 'Missouri';
  } else if (zipcode >= 59000 && zipcode <= 59999) {
    st = 'MT';
    // 'Montana';
  } else if (zipcode >= 27000 && zipcode <= 28999) {
    st = 'NC';
    // 'North Carolina';
  } else if (zipcode >= 58000 && zipcode <= 58999) {
    st = 'ND';
    // 'North Dakota';
  } else if (zipcode >= 68000 && zipcode <= 69999) {
    st = 'NE';
    // 'Nebraska';
  } else if (zipcode >= 88900 && zipcode <= 89999) {
    st = 'NV';
    // 'Nevada';
  } else if (zipcode >= 3000 && zipcode <= 3899) {
    st = 'NH';
    // 'New Hampshire';
  } else if (zipcode >= 7000 && zipcode <= 8999) {
    st = 'NJ';
    // 'New Jersey';
  } else if (zipcode >= 87000 && zipcode <= 88499) {
    st = 'NM';
    // 'New Mexico';
  } else if ((zipcode >= 10000 && zipcode <= 14999) || zipcode === 6390 || zipcode === 501 || zipcode === 544) {
    st = 'NY';
    // 'New York';
  } else if (zipcode >= 43000 && zipcode <= 45999) {
    st = 'OH';
    // 'Ohio';
  } else if ((zipcode >= 73000 && zipcode <= 73199) || (zipcode >= 73400 && zipcode <= 74999)) {
    st = 'OK';
    // 'Oklahoma';
  } else if (zipcode >= 97000 && zipcode <= 97999) {
    st = 'OR';
    // 'Oregon';
  } else if (zipcode >= 15000 && zipcode <= 19699) {
    st = 'PA';
    // 'Pennsylvania';
  } else if (zipcode >= 300 && zipcode <= 999) {
    st = 'PR';
    // 'Puerto Rico';
  } else if (zipcode >= 2800 && zipcode <= 2999) {
    st = 'RI';
    // 'Rhode Island';
  } else if (zipcode >= 29000 && zipcode <= 29999) {
    st = 'SC';
    // 'South Carolina';
  } else if (zipcode >= 57000 && zipcode <= 57999) {
    st = 'SD';
    // 'South Dakota';
  } else if (zipcode >= 37000 && zipcode <= 38599) {
    st = 'TN';
    // 'Tennessee';
  } else if (
    (zipcode >= 75000 && zipcode <= 79999) ||
    (zipcode >= 73301 && zipcode <= 73399) ||
    (zipcode >= 88500 && zipcode <= 88599)
  ) {
    st = 'TX';
    // 'Texas';
  } else if (zipcode >= 84000 && zipcode <= 84999) {
    st = 'UT';
    // 'Utah';
  } else if (zipcode >= 5000 && zipcode <= 5999) {
    st = 'VT';
    // 'Vermont';
  } else if ((zipcode >= 20100 && zipcode <= 20199) || (zipcode >= 22000 && zipcode <= 24699) || zipcode === 20598) {
    st = 'VA';
    // 'Virgina';
  } else if (
    (zipcode >= 20000 && zipcode <= 20099) ||
    (zipcode >= 20200 && zipcode <= 20599) ||
    (zipcode >= 56900 && zipcode <= 56999)
  ) {
    st = 'DC';
    // 'Washington DC';
  } else if (zipcode >= 98000 && zipcode <= 99499) {
    st = 'WA';
    // 'Washington';
  } else if (zipcode >= 24700 && zipcode <= 26999) {
    st = 'WV';
    // 'West Virginia';
  } else if (zipcode >= 53000 && zipcode <= 54999) {
    st = 'WI';
    // 'Wisconsin';
  } else if (zipcode >= 82000 && zipcode <= 83199) {
    st = 'WY';
    // 'Wyoming';
  } else {
    st = 'none';
  }
  if (st.toUpperCase() === stateString.toUpperCase()) {
    return true;
  }
  return false;
};

export const fullNameValidation = (fieldName, value) => {
  const errors = {};
  if (!value) {
    errors[fieldName] = `${i18n.t(rk.POQ_FULLNAME_REQUIRED_ERROR)}`;
  } else if (value.length > 50) {
    errors[fieldName] = `${i18n.t(rk.POQ_MAX_LENGTH_ERROR)}`;
  } else if (!value.match(globalConstants.ERROR_REGEX.otherAddress)) {
    errors[fieldName] = `${i18n.t(rk.POQ_FULLNAME_ERROR)}`;
  }
  return errors;
};

export const addressLineValidation = (fieldName, value) => {
  const errors = {};
  if (!value) {
    errors[fieldName] = `${i18n.t(rk.POQ_RESIDENTIAL_REQUIRED_ERROR)}`;
  } else if (!value.match(globalConstants.ERROR_REGEX.otherAddress)) {
    errors[fieldName] = `${i18n.t(rk.POQ_RESIDENTIAL_ERROR)}`;
  }
  return errors;
};

export const cityValidation = (fieldName, value) => {
  const errors = {};
  if (!value) {
    errors[fieldName] = `${i18n.t(rk.POQ_CITY_REQUIRED_ERROR)}`;
  } else if (!value.match(globalConstants.ERROR_REGEX.otherAddress)) {
    errors[fieldName] = `${i18n.t(rk.POQ_CITY_ERROR)}`;
  }
  return errors;
};

export const zipCodeValidation = (fieldName, value) => {
  const errors = {};
  if (!value) {
    errors[fieldName] = `${i18n.t(rk.POQ_ZIPCODE_REQUIRED_ERROR)}`;
  } else if (!value.match(globalConstants.ERROR_REGEX.zipCode)) {
    errors[fieldName] = `${i18n.t(rk.POQ_ZIPCODE_ERROR)}`;
  } else if (value.length < 5 || value.length >= 11) {
    errors[fieldName] = `${i18n.t(rk.POQ_ZIPCODE_ERROR)}`;
  }
  return errors;
};

export const stateValidation = (fieldName, value) => {
  const errors = {};
  if (!value) {
    errors[fieldName] = `${i18n.t(rk.POQ_STATE_REQUIRED_ERROR)}`;
  }
  return errors;
};

export const getCustomerType = (customerType) => {
  const type = customerType.toLowerCase();
  if (type.includes('primary')) {
    return globalConstants.CUSTOMER_MAPPING.primary;
  }
  if (type.includes('co')) {
    return globalConstants.CUSTOMER_MAPPING.cobuyer;
  }
  if (type.includes('guarantor')) {
    return globalConstants.CUSTOMER_MAPPING.guarantor;
  }
  return globalConstants.CUSTOMER_MAPPING.other;
};

export const selectFirstPaymentProfileInDropDownList = () => {
  const el = document.getElementById('payment-profile-accounts-select');
  if (el) {
    el.selectedIndex = 0;
  }
};

export const getFormattedText = (text, linkText, linkValue) => {
  let formattedParagraph = text;
  const formattedLink = `<a className='c-btn c-btn--secondary c-btn--link' type='button' href=${linkValue}>${linkText}</a>`;
  formattedParagraph = formattedParagraph.replace(`<${linkText}>`, formattedLink);
  return formattedParagraph;
};

export const noop = () => {
  // Default empty function
};

/**
 *function to set rootPath in appProps and persist the root to get back to dashboard from externals application
 * @param string rootPath
 */
export const setRootPathInAppProps = (rootPath) => {
  if (rootPath) {
    const appProps = JSON.parse(get('appProps') || '{}');
    set('appProps', JSON.stringify({ ...appProps, rootPath }));
  }
};

export const capitalizeFirstLetter = (str) => {
  if (str.length === 0) {
    return '';
  }

  const lowerCaseStr = str.toLowerCase();
  return lowerCaseStr.charAt(0).toUpperCase() + lowerCaseStr.slice(1);
};

export const classNames = (...args) => {
  if (!Array.isArray(args)) {
    return null;
  }
  return args
    .reduce((acc, currentItem) => {
      if (Array.isArray(currentItem)) {
        return `${acc} ${currentItem.join(' ')}`;
      }
      if (typeof currentItem === 'string') {
        return `${acc} ${currentItem}`;
      }
      if (typeof currentItem === 'object') {
        const objectClassNames = Object.keys(currentItem).filter((key) => currentItem[key]);
        if (objectClassNames.length > 0) {
          return `${acc} ${classNames(...objectClassNames)}`;
        }
        return acc;
      }
      return acc;
    }, '')
    .trim();
};
/**
 *function to check for 'fatal' errors in the call we make for account data to graphql.
 * @param Array errors
 */
export const hasFatalError = (errors) => {
  // Ignore this error since it's 'expected' for now...
  if (errors && errors[0]?.path[4] === 'Payoff' && errors[0]?.message === 'Failed to fetch Payoff data') {
    return false;
  }

  if (errors) {
    return true;
  }

  return false;
};

export const removeNumbersFromString = (str) => {
  return str.replace(/\d+/g, '');
};

// Resolves external ID when it's missing.
export const resolveExternalId = () => {
  const source = window?.xprops && window?.xprops?.source;
  const isMyAudi = source === globalConstants.MY_AUDI_SOURCE || source === globalConstants.MY_AUDI_MOBILE_SOURCE;

  if (isMyAudi && window?.xprops && window.xprops?.externalId) {
    return window.xprops.externalId;
  }

  return getPartyId();
};

/// function returns true if the dashboard is being consumed by myAudi app
export const isMyAudiApp = () => {
  const source = getSourceId();

  if (!source) return false;

  return source === globalConstants.MY_AUDI_SOURCE || source === globalConstants.MY_AUDI_MOBILE_SOURCE;
};

export const isNumber = (value) => {
  return !Number.isNaN(parseInt(value, 10));
};

/**
 * Is the address in a state that requires multiple signatures on docs
 *
 * @param addresses the address to obtain garaging address
 * @return {*|boolean}
 */
export const isMultipleSignaturesRequired = (stateOrProvince) => {
  if (stateOrProvince) {
    return globalConstants.USA_STATES_REQUIRING_ALL_PARTY_SIGNATURES.includes(stateOrProvince.toUpperCase());
  }
  return false;
};

/**
 * Function to determine if account is eligible for Due Date Change
 * @param account the account data
 * @param parties the parties on the account as an array of json objects
 * @param country the country that the account is located
 * @returns boolean true if eligible, false otherwise
 */
export const isDueDateChangeEligible = (isDueDateEligible, parties, country, garagingState) => {
  const isCountryUSA = country === globalConstants.COUNTRY_USA;
  const hasMultipleParties = parties?.length > 1;
  const multipleSignaturesRequired = isMultipleSignaturesRequired(garagingState);
  if (multipleSignaturesRequired && hasMultipleParties) return false;
  return isCountryUSA && isDueDateEligible;
};

/**
 * Verify string contains a number.
 * @param {string} str
 * @returns {boolean}
 */
export const isNumeric = (str) => {
  if (typeof str !== 'string') return false;
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(str) && !isNaN(parseFloat(str));
};

/**
 * Get due amount details from getPaymentDetails in the account.
 * @param {*} dueAmountDetails
 * @param {string} paymentType
 * @returns {Array} Either empty or with the matching Fees.
 */
export const getDueAmountFees = (dueAmountDetails) => {
  let totalFees = [];

  // Get current & past fees for payment started for now, check other types too.
  if (dueAmountDetails && dueAmountDetails.Current) {
    if (dueAmountDetails.Current.Fees) totalFees = totalFees.concat(dueAmountDetails.Current.Fees);
    if (dueAmountDetails.Past && dueAmountDetails.Current.Fees)
      totalFees = totalFees.concat(dueAmountDetails.Past.Fees);
    return totalFees;
  }

  return [];
};
