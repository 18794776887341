export const TEXT_TITLE = 'Text.TItle';

export const CONFIRMATION_DIALOG_TITLE = 'ConfirmationDialog.title';
export const CONFIRMATION_DIALOG_SUBTITLE = 'ConfirmationDialog.subtitle';
export const CONFIRMATION_DIALOG_OK_BUTTON = 'ConfirmationDialog.button.ok';
export const CONFIRMATION_DIALOG_CANCEL_BUTTON = 'ConfirmationDialog.button.cancel';

export const COMPANY_AUDI_FINANCIAL_SERVICES = 'Company.AudiFinancialServices';
export const COMPANY_VOLKSWAGEN_CREDIT = 'Company.VolkswagenCredit';
export const COMPANY_VOLKSWAGEN_FINANCIAL_SERVICES = 'Company.Volkswagen.Financial.Services';
export const BRAND_VOLKSWAGEN = 'Brand.VW';
export const BRAND_AUDI = 'Brand.Audi';

export const HEADER_SCHEDULED = 'Header.Scheduled';
export const HEADER_PENDING = 'Header.Pending';
export const HEADER_POSTED = 'Header.Posted';

export const LABEL_ACCOUNT = 'Label.Account';
export const LABEL_VIN = 'Label.VIN';
export const LABEL_NAME = 'Label.Name';

export const LABEL_BRAND = 'Label.Brand';

export const TOKEN_EXPIRATION_TITLE = 'Token.Expiration.Title';
export const TOKEN_EXPIRATION_TEXT = 'Token.Expiration.Text';
export const TOKEN_EXPIRATION_TEXT2 = 'Token.Expiration.Text2';
export const TOKEN_EXPIRATION_CONFIRM_BUTTON = 'Token.Expiration.Confirm.Button';
export const TOKEN_EXPIRATION_LOGOUT_BUTTON = 'Token.Expiration.Logout.Button';

export const BUTTON_MAKEAPAYMENT = 'Button.MakeAPayment';
export const BUTTON_CANCEL = 'Button.Cancel';
export const BUTTON_CONTINUE = 'Button.Continue';
export const BUTTON_CONFIRM = 'Button.Confirm';
export const BUTTON_CONFIRM_CANCELLATION = 'Button.ConfirmCancellation';
export const BUTTON_STAY_ENROLLED = 'Button.StayEnrolled';
export const BUTTON_SUBMIT = 'Button.Submit';
export const BUTTON_ENROLL = 'Button.Enroll';
export const BUTTON_SAVE = 'Button.Save';
export const BUTTON_NEXT = 'Button.Next';
export const BUTTON_YES = 'Button.Yes';
export const BUTTON_NO = 'Button.No';
export const BUTTON_BACK = 'Button.Back';
export const BUTTON_CLOSE = 'Button.Close';
export const BUTTON_ONETIMEPAYMENT = 'Button.OneTimePayment';
export const BUTTON_NOTHANKS = 'Button.NoThanks';
export const BUTTON_ADD_YOUR_PAYMENT_ACCT = 'Button.AddYourPaymentAccount';
export const BUTTON_EDIT_YOUR_PAYMENT_ACCT = 'Button.EditYourPaymentAccount';
export const BUTTON_NO_BACK_TO_FORM = 'Button.NoBackToForm';
export const BUTTON_BACK_TO_FORM = 'Button.BackToForm';

export const CANCEL_BUTTON_NO_BACK_TO_FORM = 'CancelButton.NoBackToForm';
export const BUTTON_ENROLL_IN_AUTOPAY = 'Button.EnrollInAutopay';
export const BUTTON_ENROLL_IN_AUTO_PAY = 'Button.EnrollInAutoPay';
export const BUTTON_MAKE_ONE_TIME_PAYMENT = 'Button.MakeOneTimePayment';
export const BUTTON_NO_THANK_YOU = 'Button.NoThankYou';

export const TEXT_OK = 'Text.Ok';
export const TEXT_TRY_AGAIN = 'Text.TryAgain';
export const TEXT_IS = 'Text.Is';
export const TEXT_WAS = 'Text.Was';

export const TEXT_MANAGE_MY_ACCOUNT = 'Text.ManageMyAccount';
export const TEXT_PAYING_BY_CHECK = 'Text.PayingByCheck';

export const HEADER_LEASEDETAILS = 'Header.LeaseDetails';
export const HEADER_CONTRACTDETAILS = 'Header.ContractDetails';

// Validation errors
export const ERROR_REQUIRED = 'Error.Required';
export const ERROR_DATEMUSTBEINFUTURE = 'Error.DateMustBeInFuture';
export const ERROR_PAYMENTPASTALLOWEDDATE = 'Error.PaymentPastAllowedDate';
export const ERROR_ROUTINGNUMBER = 'Error.RoutingNumber';
export const ERROR_ACCOUNTNUMBER = 'Error.AccountNumber';
export const ERROR_MATCH_ACCOUNTNUMBER = 'Error.Match_AccountNumber';
export const ERROR_DUPLICATE_PAYMENT = 'Error.Duplicate_Payment';
export const ERROR_FORBIDDEN_PAYOFF = 'Error.Forbidden.Payoff';
export const ERROR_OVERLIMIT_PAYMENT = 'Error.Overlimit.Payment';

// Load Errors
export const ACCOUNT_NUMBER_MISSING = 'Load_Error.AccountNumber_Missing';

// Portal Errors and Labels
export const ERROR = 'Error';
export const ERROR_EMPTY_PAYMENTDATE = 'Error.Empty_PaymentDate';
export const ERROR_EMPTY_AMOUNTTOWITHDRAW = 'Error.Empty_AmountToWithdraw';
export const ERROR_EMPTY_BANKACCOUNTNUMBER = 'Error.Empty_BankAccountNumber';
export const ERROR_EMPTY_ROUTINGNUMBER = 'Error.Empty_RoutingNumber';
export const ERROR_EMPTY_NAMEONBANKACCOUNT = 'Error.Empty_NameOnBankAccount';

export const ERROR_NO_PAYMENT_PAST_DATE = 'Error.NoPaymentPastDate';
// API errors
export const API_ERROR_LOADACCOUNT = 'Api_Error.LoadAccount';
export const API_ERROR_FORBIDDEN_PAYOFF = 'Api_Error.ForbiddenPayoff';
export const API_ERROR_ONE_TIME_PAYMENT = 'Api_Error.OneTimePayment';
export const API_ERROR_AUTO_PAY = 'Api_Error.AutoPay';
export const API_ERROR_PAYMENT_PROFILE = 'Api_Error.PaymentProfile';
export const SYSTEM_ERROR = 'Error.SystemError';
export const API_ERROR_AUTOPAY_CREATE_AND_DELETE_CONFLICT = 'Api_Error.AutopayCreateConflict';
export const API_ERROR_AUTOPAY_CANCEL_CONFLICT = 'Api_Error.AutopayCancelConflict';
export const API_ERROR_PAYOFFQUOTE_ELIGIBILITY = 'Api_Error.PayoffQuoteEligibilityError';
export const API_ERROR_EXISTING_PAYOFFQUOTE = 'Api_Error.ExistingPayoffQuoteError';
export const API_ERROR_NEW_PAYOFFQUOTE = 'Api_Error.NewPayoffQuoteError';
export const API_ERROR_PAYOFFQUOTE_PAYMENT = 'Api_Error.PayoffQuotePayment';

// Technical Error
export const ERROR_TECHNICAL_ERROR_LABEL = 'Error.TechnicalErrorLabel';
export const ERROR_TECHNICAL_ERROR = 'Error.TechnicalError';

// System Error
export const ERROR_SYSTEM_ERROR_CURRENTLY_UNABLE_TO_PROCESS = 'Error.SystemError.CurrentlyUnableToProcess';

// Account Labels
export const CONTRACT_HOLDER_LABEL = 'ContractHolder.Label';

// Contract Summary
export const CONTRACT_SUMMARY_PAYMENTS_LABEL = 'ContractSummary.Payments';
export const CONTRACT_SUMMARY_PAYMENTS_TEXT = 'ContractSummary.Of';
export const CONTRACT_SUMMARY_CONTRACT_DATE_LABEL = 'ContractSummary.ContractDate';
export const CONTRACT_SUMMARY_FINAL_PAYMENTS_LABEL = 'ContractSummary.FinalPayments';

// Contract Details
export const CONTRACT_DETAIL_TERM_LABEL = 'ContractDetail.Term';
export const CONTRACT_DETAIL_TERM_MONTHS_LABEL = 'ContractDetail.TermMonths';
export const CONTRACT_DETAIL_SECURITY_DEPOSIT_LABEL = 'ContractDetail.SecurityDeposit';
export const CONTRACT_DETAIL_TURN_IN_DATE_LABEL = 'ContractDetail.TurnInDate';
export const CONTRACT_DETAIL_PAYMENT_REMAINING_LABEL = 'ContractDetail.PaymentRemaining';
export const CONTRACT_DETAIL_RESIDUAL_AMOUNT_LABEL = 'ContractDetail.ResidualAmount';
export const CONTRACT_DETAIL_LEASE_LABEL = 'ContractDetail.Lease';
export const CONTRACT_DETAIL_LOAN_LABEL = 'ContractDetail.Loan';
export const CONTRACT_DETAIL_PREMIUM_LABEL_AUDI = 'ContractDetail.Premier';
export const CONTRACT_DETAIL_PREMIUM_LABEL_VW = 'VW.ContractDetail.Premier';
export const CONTRACT_DETAIL_INTEREST_RATE_LABEL = 'ContractDetail.InterestRate';
export const CONTRACT_DETAIL_PRINCIPAL_YTD_LABEL = 'ContractDetail.PrincipalYtd';
export const CONTRACT_DETAIL_INTEREST_YTD_LABEL = 'ContractDetail.InterestYtd';

// Payment Summary
export const PAYMENT_SUMMARY_HEADER_LABEL = 'PaymentSummary.Header.Label';
export const PAYMENT_SUMMARY_HEADER_LABEL_CANADA = 'PaymentSummary.YourNextBill';
export const PAYMENT_SUMMARY_BALANCE_LABEL = 'PaymentSummary.AmountDue';
export const PAYMENT_SUMMARY_BALANCE_LABEL_CANADA = 'PaymentSummary.AmountOwed';
export const PAYMENT_SUMMARY_BILL_LABEL = 'PaymentSummary.DueDate';
export const PAYMENT_SUMMARY_BILL_LABEL_CANADA = 'PaymentSummary.BillDue';
export const PAYMENT_SUMMARY_MAKE_PAYMENT_LABEL = 'PaymentSummary.MakeAPayment';
export const PAYMENT_SUMMARY_REQUEST_POQ_LABEL = 'PaymentSummary.RequestPayoffQuote';
export const PAYMENT_SUMMARY_GET_POQ_LABEL = 'PaymentSummary.GetPayoffQuote';
export const PAYMENT_SUMMARY_INACTIVE_LABEL = 'PaymentSummary.AutopayInActive';
export const PAYMENT_SUMMARY_ENROLL_LABEL = 'PaymentSummary.Enroll';
export const PAYMENT_SUMMARY_ENROLL_IN_AUTOPAY_LABEL = 'PaymentSummary.EnrollInAutopay';
export const PAYMENT_SUMMARY_CANCEL_AUTOPAY_LABEL = 'PaymentSummary.CancelAutopay';
export const PAYMENT_SUMMARY_CANCEL_LABEL = 'PaymentSummary.Cancel';
export const PAYMENT_SUMMARY_AUTOPAY_LABEL = 'PaymentSummary.Autopay';
export const PAYMENT_SUMMARY_PAPERLESS_ACTIVE_LABEL = 'PaymentSummary.PaperlessActive';
export const PAYMENT_SUMMARY_IN_PAPERLESS_INACTIVE_LABEL = 'PaymentSummary.PaperlessInactive';
export const PAYMENT_SUMMARY_MESSAGES_AUTOPAY_ENABLED_LABEL = 'PaymentSummary.AutopayEnabledMessage';
export const PAST_DUE_PARTIAL_MESSAGE_TEXT = 'PaymentSummary.PastDueMessage';
export const ARIA_PAPERLESS_ENROLL = 'Aria.Paperless.Enroll';
export const ARIA_PAPERLESS_CANCEL = 'Aria.paperless.Cancel';
export const PAYMENT_SUMMARY_PAST_DUE_TEXT = 'PaymentSummary.PastDue.Text';

// EOT Payment
export const EOT_PAYMENT_AMOUNT_OWED = 'EOTPayment.AmountOwed';
export const EOT_PAYMENT_DUE_DATE = 'EOTPayment.DueDate';

// Manage Account
export const LABEL_MANAGE_ACCOUNT_HEADING = 'ManageAccount.Heading';
export const LABEL_MANAGE_ACCOUNT_DESCRIPTION_AUDI = 'ManageAccount.Description';
export const LABEL_MANAGE_ACCOUNT_DESCRIPTION_VW = 'ManageAccount.Description.VW';
export const MAKE_YOUR_CHECK_PAYABLE_TO = 'ManageAccount.MakeYourCheckPayableTo';
export const AUDI_FINANCIAL_SERVICES = 'ManageAccount.AudiFinancialServices';

// Account type
export const LABEL_ACCOUNTTYPE_ACCOUNTTYPE_HEADING = 'AccountType.AccountType';
export const LABEL_ACCOUNTTYPE_CHECKING = 'AccountType.Checking';
export const LABEL_ACCOUNTTYPE_SAVINGS = 'AccountType.Savings';

// Make a payment messages
export const NO_PAYMENT_ACCOUNT_TITLE = 'NoPayment.Account.Title';
export const NO_PAYMENT_ACCOUNT_MSG = 'NoPayment.Account.Message';
export const ARIA_PAYMENT_ADDRESSES = 'Aria.Payment.Addresses';
export const ARIA_PAYMENTS_REMAINING_TEXT = 'Aria.Payments.Remaining.Text';
export const ARIA_TOTAL_PAYMENTS_LABEL = 'Aria.Total.Payments.Label';
export const PAYMENT_ALREADY_AUTOPAY_ENROLLED_MSG = 'MakePayment.AutopayWarning.Message';
export const PAYMENT_ALREADY_AUTOPAY_ENROLLED_TITLE = 'MakePayment.AutopayWarning.Title';
// TRANSACTIONS
export const TRANSACTIONS_DATE_LABEL = 'Transactions.Date';
export const TRANSACTIONS_REFERENCE_LABEL = 'Transactions.Reference';
export const TRANSACTIONS_BANK_ACCOUNT_LABEL = 'Transactions.BankAccount';
export const TRANSACTIONS_AMOUNT_LABEL = 'Transactions.Amount';
export const TRANSACTIONS_PAYMENT_TYPE_LABEL = 'Transactions.PaymentType';
export const LOGIN_BUTTON_LABEL = 'Login.Login';
export const TRANSACTIONS_POSTED = 'Transactions.Posted';
export const TRANSACTIONS_PENDING = 'Transactions.Pending';
export const TRANSACTION_POSTED_EMPTY = 'Transactions.Posted.None';
export const TRANSACTION_PENDING_EMPTY = 'Transactions.Pending.None';
export const TRANSACTIONS_API_ERROR = 'Transactions.Api.Error';
export const STATEMENTS_API_ERROR = 'Statements.Api.Error';
export const TRANSACTION_STATEMENTS_EMPTY = 'Transactions.Statements.None';
export const TRANSACTION_STATMEENTS_LABEL_RECENT = 'Transactions.Statements.Label.Recent';
export const TRANSACTION_SCHEDULED_EMPTY = 'Transactions.Scheduled.None';
export const TRANSACTIONS_PAYMENT_METHOD_LABEL = 'Transactions.PaymentMethod';
export const TRANSACTIONS_PAYMENT_ACCOUNT_LABEL = 'Transactions.PaymentAccount';
export const TRANSACTIONS_PAYMENT_DESCRIPTION_LABEL = 'Transaction.Description';
export const TRANSACTIONS_TABLE_LABEL = 'Transactions.Table.Label';
export const TRANSACTIONS_TAB_LABEL = 'Transactions.Tab.Label';
export const TRANSACTIONS_STATEMENTS_LABEL = 'Statements.Table.Label';
export const STATEMENTS_TAB_LABEL = 'Statements.Tab.Label';
export const AMORTIZATION_TAB_LABEL = 'Amortization.Tab.Label';
export const STATEMENTS_LABEL_VIEW = 'Statements.Label.View';
export const STATEMENS_LABEL_DOWNLOAD = 'Statements.Label.Download';
export const CANCEL_PAYMENT_TOOLTIP = 'Cancel.Payment.Tooltip';
export const ARIA_CANCEL_PAYMENT = 'Aria.Cancel.Payment';
export const PAYMENT_PROCESSED_LABEL = 'Payment.Processed.Label';
export const INTERESTPAID_TAB_LABEL = 'InterestPaid.Tab.Label';
export const INTERESTPAID_SUMMARY = 'InterestPaidSummary.Text';
export const INTERESTPAID_LINE1 = 'InterestPaid.Text.Line1';
export const INTERESTPAID_LINE1_PREV = 'InterestPaid.Text.Line1.Prev';
export const INTERESTPAID_LINE2 = 'InterestPaid.Text.Line2';
export const INTERESTPAID_NOTE1 = 'InterestPaid.Text.Note1';
export const INTERESTPAID_NOTE2 = 'InterestPaid.Text.Note2';
export const INTERESTPAID_LINK = 'InterestPaid.LINK.Label';
export const TECHNICAL_ERROR_HEADER = 'TechnicalError.Header';
export const TECHNICAL_ERROR_CONTENT = 'TechnicalError.Content';
export const INTERESTPAID_AUDISERV = 'InterestPaid.Text.AudiServices';
export const INTERESTPAID_VWSERV = 'InterestPaid.Text.VWServices';
export const EOT_PENDING_PAYMENT_TOOLTIP = 'InterestPaid.Payment.Tooltip';

// STATEMENTS
export const STATEMENTS_RECENT = 'Statements.Recent';
export const STATEMENTS_EMPTY = 'Statements.Empty';
export const STATEMENTS_TABLE_INSTRUCTION = 'Statements.TableInstruction';
export const STATEMENTS_READER_DOWNLOAD_LABEL = 'Statements.ReaderDownloadLabel';
// Form Fields
export const ROUTING_NUMBER_LABEL = 'Label.RoutingNumber';
export const ACCOUNT_NUMBER_LABEL = 'Label.AccountNumber';
export const CONFIRM_ACCOUNT_NUMBER_LABEL = 'Label.ConfirmAccountNumber';
export const PAYMENT_ACCOUNT_LABEL = 'Label.PaymentAccount';
export const PAYMENT_ACCOUNT_LABEL_REQUIRED = 'Label.PaymentAccountRequired';

export const PLACEHOLDER_PAYMENT_ACCOUNT = 'Placeholder.PaymentAccount';

// Make a Payment Flow
export const MAKE_A_PAYMENT_ADD_PAYMENT_NOTIFICATION = 'MakeAPayment.AddPaymentNotification';

export const MAKE_A_PAYMENT_PAST_DUE_THIRTY_DAY_NOTIFICATION = 'MakeAPayment.PastDueThirtyDaysNotification';
export const MAKE_A_PAYMENT_OUTSTANDING_AMOUNT = 'MakePayment.OutstandingAmount';
export const TEXT_CANCEL_CONFIRMATION = 'Text.CancelConfirmation';
export const TEXT_QUIT_CONFIRMATION = 'Text.QuitConfirmation';
export const TEXT_CANCEL_ADD_PAYMENT_ACCOUNT = 'Text.CancelConfirmationAddPaymentAccount';
export const TEXT_CANCEL_EDIT_PAYMENT_ACCOUNT = 'Text.CancelConfirmationEditPaymentAccount';
export const TEXT_QUIT_ADD_PAYMENT_ACCOUNT = 'Text.QuitConfirmationAddPaymentAccount';
export const TEXT_QUIT_EDIT_PAYMENT_ACCOUNT = 'Text.QuitConfirmationEditPaymentAccount';
export const TEXT_CANCEL_AUTOPAY_ENROLL = 'Text.CancelConfirmationAutoPayEnroll';
export const TEXT_QUIT_AUTOPAY_ENROLL = 'Text.QuitConfirmationAutoPayEnroll';
export const TITLE_TEXT_CANCEL_CONFIRMATION_MAKE_PAYMENT = 'TitleText.CancelConfirmationMakePayment';
export const TEXT_CANCEL_MAKE_PAYMENT_USA = 'Text.CancelConfirmationMakePaymentUSA';
export const TEXT_CANCEL_MAKE_PAYMENT = 'Text.CancelConfirmationMakePayment';
export const ARIA_AUTOPAY_ENROLL = 'Aria.Autopay.Enroll';
export const ARIA_AUTOPAY_CANCEL = 'Aria.Autopay.Cancel';

export const TEXT_CANEL_WARNING = 'Text.CancelWarning';
export const TITLE_CONFIRMATION = 'Title.Confirmation';
export const MAKE_PAYMENT_MAKE_PAYMENT = 'MakePayment.MakePayment';
export const MAKE_PAYMENT_VERIFY_DETAILS = 'MakePayment.VerifyDetails';
export const MAKE_PAYMENT_PAYMENT_CONFIRM = 'MakePayment.PaymentConfirm';
export const MAKE_PAYMENT_CANCEL = 'MakePayment.Cancel';
export const MAKE_PAYMENT_SURVEY = 'MakePayment.Survey';
export const PAYMENT_FLOW_ADD_YOUR_PAYMENT_ACCOUNT_LABEL = 'PaymentFlow.AddYourPaymentAccount';
export const PAYMENT_FLOW_ADD_NEW_PAYMENT_ACCOUNT_LABEL = 'PaymentFlow.AddNewPaymentAccount';
export const PAYMENT_FLOW_EDIT_YOUR_PAYMENT_ACCOUNT_LABEL = 'PaymentFlow.EditYourPaymentAccount';
export const PAYMENT_FLOW_EDIT_PAYMENT_ACCOUNT_LABEL = 'PaymentFlow.EditPaymentAccount';

export const PAYMENT_DISCLAIMER_1 = 'Payment.Disclaimer1';
export const PAYMENT_DISCLAIMER_2 = 'Payment.Disclaimer2';

export const PAYMENT_DISCLOSURE = 'Label.PaymentDisclosure';

export const MAKE_PAYMENT_CURRENT_PAYMENT_CONFIRMATION = 'MakePayment.current-payment.Confirmation';
export const MAKE_PAYMENT_ONE_TIME_PAYMENT_CONFIRMATION = 'MakePayment.one-time-payment.Confirmation';
export const MAKE_PAYMENT_PRINCIPAL_PAYMENT_CONFIRMATION = 'MakePayment.principal-payment.Confirmation';
export const BUTTON_ADD_PAYMENT_ACCOUNT = 'Button.add-payment-account';
export const BUTTON_EDIT_PAYMENT_ACCOUNT = 'Button.edit-payment-account';
export const BUTTON_EDIT_ACCOUNT = 'Button.Edit.Account';

// Make a payment
export const LABEL_NEXT_DUE = 'Label.NextDue';
export const LABEL_NEXT_AMOUNT_DUE = 'Label.NextAmount.Due';
export const LABEL_PAST_DUE = 'Label.PastDue';
export const LABEL_TAXES_ON_FEES = 'Label.TaxesOnFees';
export const LABEL_TOTAL = 'Label.Total';
export const LABEL_PAYMENTACCOUNT = 'Label.PaymentAccount';
export const LABEL_PAYMENTDATE = 'Label.PaymentDate';
export const LABEL_PAYMENT_CONFIRMATIONNUMBER = 'Label.Payment.ConfirmationNumber';
export const LABEL_PAYMENTFEES = 'Label.PaymentFees';
export const LABEL_LATECHARGE = 'Label.LateCharge';
export const LABEL_LATECHARGES = 'Label.LateCharges';
export const LABEL_PAYMENTOF = 'Label.PaymentOf';
export const LABEL_WILLPOSTON = 'Label.WillPostOn';
export const LABEL_WITHDRAWN = 'Label.WillBeWithdrawn';
export const LABEL_PRINT = 'Label.Print';
export const LABEL_PAYMENTDETAILS = 'Label.PaymentDetails';
export const CURRENT_PAYMENT_LABEL = 'Label.CurrentPayment';
export const NEXT_AMOUNT_DUE_LABEL = 'Label.NextAmountDue';
export const THIS_WILL_BE_DUE_ON = 'Label.ThisWillBeDueOn';
export const BILL_DUE_LABEL = 'Label.BillDue';
export const CURRENT_AMOUNT_OWED_LABEL = 'Label.CurrentAmountOwed';
export const NEXT_AMOUNT_DUE_$_LABEL = 'Label.NextAmountDue$';
export const OTHER_AMOUNT_LABEL = 'Label.OtherAmount';
export const OTHER_AMOUNT_INFO_LABEL = 'Label.OtherAmount.Info';

export const PAST_DUE_PAY_NOW_LABEL = 'Label.PastDue.PayNow';
export const THIS_WAS_DUE_LABEL = 'Label.ThisWasDue';
export const PAST_DUE_AMOUNT_LABEL = 'Label.PastDue$';
export const PAST_DUE_INFO_LABEL = 'Label.PastDue.Info';
export const TOTAL_AMOUNT_LABEL = 'Label.TotalAmount';
export const MAKE_A_PAYMENT_VERIFY_INFO_LABEL = 'Label.MakeAPaymentVerify.Info';
export const MAKE_A_PAYMENT_INFO_LABEL = 'Label.MakeAPayment.Info';
export const PRINCIPAL_PAYMENT_LABEL = 'Label.PrincipalPayment';
export const SELECT_A_PAYMENT_TYPE_LABEL = 'Label.SelectAPaymentType';
export const WHAT_PAYMENT_WOULD_YOU_LIKE_TO_MAKE_LABEL = 'Label.WhatPaymentWouldYouLikeToMake';
export const PAYMENT_AMOUNT_LABEL = 'Label.PaymentAmount';
export const PAYMENT_DATE_LABEL = 'Label.PaymentDate';
export const MAKE_PAYMENT_CHECKING = 'MakePayment.Checking';
export const ERROR_PAYMENT_DATE_SELECT = 'Error.PaymentSelect';
export const ERROR_PAYMENT_VALIDITY = 'Error.PaymentValid';
export const INFO_VALID_PAYMENT = 'Error.ValidPaymentInfo';
export const ERROR_MIN_PAYMENT = 'Error.MinPayment';
export const ERROR_MAX_PAYMENT = 'Error.MaxPayment';

// Auto pay
export const AUTO_PAY_ADD_PAYMENT_NOTIFICATION = 'AutoPay.AddPaymentNotification';
export const AUTO_PAY_VERIFY_TITLE = 'AutoPay.Verify.Title';
export const AUTO_PAY_MONTHLY_PAYMENT = 'AutoPay.MonthlyPayment';
export const AUTO_PAY_PAYMENT_ACCOUNT = 'AutoPay.PaymentAccount';
export const AUTO_PAY_FIRST_WITHDRAWAL_DATE = 'AutoPay.DateOfFirstWithdrawal';
export const AUTO_PAY_LAST_WITHDRAWAL_DATE = 'AutoPay.DateOfLastWithdrawal';
export const AUTO_PAY_FIRST_WITHDRAWAL_DATE_ENHANCED = 'AutoPay.FirstWithdrawalDate';
export const AUTO_PAY_FINAL_WITHDRAWAL_DATE = 'AutoPay.FinalWithdrawalDate';
export const AUTO_PAY_PAYMENTS_REMAINING = 'AutoPay.PaymentsRemaining';
export const AUTO_PAY_AGREEMENT_ONE = 'AutoPay.Agreement.One';
export const AUTO_PAY_AGREEMENT_TWO = 'AutoPay.Agreement.Two';
export const AUTO_PAY_AGREEMENT_TWO_VW = 'AutoPay.Agreement.Two.VW';
export const AUTO_PAY_AGREEMENT_THREE = 'AutoPay.Agreement.Three';
export const AUTO_PAY_AGREEMENT_THREE_VW = 'AutoPay.Agreement.Three.VW';
export const AUTO_PAY_AGREEMENT_ONE_ENHANCED = 'AutoPay.Agreement.One.Enhanced';
export const AUTO_PAY_AGREEMENT_TWO_ENHANCED = 'AutoPay.Agreement.Two.Enhanced';
export const AUTO_PAY_CHECKBOX = 'AutoPay.CheckBox';
export const AUTO_PAY_CHECKBOX_ENHANCED = 'AutoPay.CheckBox.Enhanced';
export const AUTO_PAY_CHECKBOX_ONE = 'AutoPay.CheckBox.One';
export const AUTO_PAY_CHECKBOX_TWO = 'AutoPay.CheckBox.Two';
export const AUTO_PAY_REFERENCE_NUMBER = 'AutoPay.ReferenceNumber';
export const AUTO_PAY_BEGIN = 'AutoPay.Begin';
export const AUTO_PAY_MAKEPAYMENT_HEADER = 'AutoPay.MakePayment.Header';
export const AUTO_PAY_MAKEPAYMENT_NOTICE_ENHANCED = 'AutoPay.MakePayment.Notice.Enhanced';
export const AUTO_PAY_MAKEPAYMENT_NOTICE_ONE = 'AutoPay.MakePayment.Notice.One';
export const AUTO_PAY_MAKEPAYMENT_NOTICE_TWO = 'AutoPay.MakePayment.Notice.Two';
export const AUTO_PAY_MAKEPAYMENT_NOTICE_THREE = 'AutoPay.MakePayment.Notice.Three';
export const AUTO_PAY_MAKEPAYMENT_NOTICE_FOUR = 'AutoPay.MakePayment.Notice.Four';
export const AUTO_PAY_SUBMIT_SUCCESS = 'Autopay.Submit.Success';
export const TITLE_AUTOPAY_ENROLLMENT = 'Title.AutopayEnrollment';
export const TITLE_AUTOPAY_ENROLLMENT_ENHANCED = 'Title.AutopayEnrollment.Enhanced';
export const TEXT_AUTOPAY_ENROLLMENT_INFO_1 = 'Text.AutopayEnrollmentInfo-1';
export const TEXT_AUTOPAY_ENROLLMENT_INFO_2 = 'Text.AutopayEnrollmentInfo-2';
export const TEXT_AUTOPAY_ENROLLMENT_INFO_1_ENHANCED = 'Text.AutopayEnrollmentInfo-1.Enhanced';
export const TEXT_AUTOPAY_ENROLLMENT_INFO_2_ENHANCED = 'Text.AutopayEnrollmentInfo-2.Enhanced';
export const AUTO_PAY_CANCELLATION_WARNING = 'AutoPay.Cancellation.Warning';
export const AUTO_PAY_CANCEL_TEXT = 'AutoPay.Cancel.Text';
export const AUTO_PAY_CANCEL_CONFIMATION_TITLE = 'Autopay.Cancel.Confirmation.Title';
export const AUTO_PAY_CANCEL_CONFIMRATION = 'Autopay.Cancel.Confirmation';
export const AUTO_PAY_CANCEL_CONFIMATION_ENHANCED = 'Autopay.Cancel.Confirmation.Enhanced';
export const AUTO_PAY_NEXT_DATE_OF_PAYMENT_LABEL = 'AutoPay.NextDateOfPayment.Label';
export const AUTO_PAY_LAST_DATE_OF_PAYMENT_LABEL = 'AutoPay.LastDateOfPayment.Label';
export const AUTO_PAY_NEXT_WITHDRAWAL_DATE_LABEL = 'AutoPay.NextWithDrawalDate.Label';
export const AUTO_PAY_LAST_WITHDRAWAL_DATE_LABEL = 'AutoPay.LastWithDrawalDate.Label';
export const AUTO_PAY_AMOUNT_OF_FIRST_PAYMENT_LABEL = 'AutoPay.AmountOfFirstPayment.Label';
export const AUTO_PAY_STAY_ENROLLED_BUTTON = 'AutoPay.StayEnrolledButton';
export const AUTO_PAY_CANCELLATION_TITLE = 'AutoPay.Cancellation.Title';
export const AUTO_PAY_DISABLED_CONFIRMATION = 'Autopay.Disabled.Confirmation';
export const AUTO_PAY_CONFIRMATION_TITLE = 'AutoPay.Confirmation.Title';
export const AUTO_PAY_CONFIRMATION_TITLE_ENHANCED = 'AutoPay.Confirmation.Title.Enhanced';
export const AUTO_PAY_CANCELLATION_CONFIRMATION_TITLE = 'AutoPay.Cancellation.Confirmation.Title';
export const AUTO_PAY_ENROLLMENT_CANCEL = 'AutoPay.Enrollment.Cancel';
export const AUTO_PAY_ENROLLMENT_TITLE = 'AutoPay.Enrollment.Title';
export const AUTO_PAY_VERIFY_ENROLLMENT_TITLE = 'AutoPay.VerifyEnrollment.Title';
export const AUTO_PAY_REVIEW_ENROLLMENT_TITLE = 'AutoPay.ReviewEnrollment.Title';
export const AUTO_PAY_ACCOUNT = 'AutoPay.Account';
export const AUTO_PAY_ALL_SET = 'AutoPay.AllSet.Label';
export const AUTO_PAY_CANCEL_WARNING_TITLE = 'AutoPay.Cancel.Warning.Title';
export const AUTO_PAY_CANCEL_WARNING_MESSAGE = 'AutoPay.Cancel.Warning.Message';
export const ACCOUNT_ENDING_IN = 'Account.Ending.In';
export const ENTER_DATE_FORMAT_ARIA = 'Enter.Date.Format.Aria';
export const NAME_ON_BANK_ACCOUNT = 'Autopay.NameOnBankAccount';
export const NAME_ON_ACCOUNT = 'Autopay.NameOnAccount';
export const ACCORDION_NEED_HELP_LABEL = 'Accordinon.NeedHelp';
export const AUTO_PAY_NO_ENROLLMENT_TITLE = 'AutoPay.No.Enrollment.Title';
export const AUTO_PAY_NO_ENROLLMENT_DESCRIPTION_ONE = 'AutoPay.No.Enrollment.Description.One';
export const AUTO_PAY_NO_ENROLLMENT_DESCRIPTION_TWO = 'AutoPay.No.Enrollment.Description.Two';
export const AUTO_PAY_NO_ENROLLMENT_ACTION_LABEL = 'AutoPay.No.Enrollment.Action.Label';
export const AUTO_PAY_NO_ENROLLMENT_DESCRIPTION_THREE = 'AutoPay.No.Enrollment.Description.Three';

// Verify Payment
export const VERIFY_PAYMENT_TITLE = 'VerifyPayment.Title';
export const VERIFY_PAYMENT_PAYMENT_DETAILS = 'VerifyPayment.PaymentDetails';
export const VERIFY_PAYMENT_PAYMENT_ACCOUNT = 'VerifyPayment.PaymentAccount';
export const VERIFY_PAYMENT_OTHER_PAYMENT_DETAILS = 'VerifyPayment.OtherPaymentDetails';
export const VERIFY_PAYMENT_PAYMENT_DATE = 'VerifyPayment.PaymentDate';
export const VERIFY_PAYMENT_TOTAL_LABEL = 'VerifyPayment.Total';
export const VERIFY_PAYMENT_TANDC = 'VerifyPayment.TandC';
export const VERIFY_PAYMENT_TANDC_LINK = 'VerifyPayment.TandClink';
export const VERIFY_PAYMENT_TANDC_AUTHORIZATION = 'VerifyPayment.TandCAuthorization';
export const VERIFY_PAYMENT_REFERENCE_NUMBER_LABEL = 'VerifyPayment.ReferenceNumberLabel';
export const BUTTON_SUBMIT_PAYMENT = 'Button.Submit.Payment';
export const SELECT_PAYMENT_CURRENT_AMOUNT_OWED_LABEL = 'SelectPayment.CurrentAmountOwed';
export const SELECT_PAYMENT_CURRENT_AMOUNT_OWED_VALUE = 'SelectPayment.CurrentAmountOwedValue';
export const SELECT_PAYMENT_OTHER_AMOUNT_LABEL = 'SelectPayment.OtherAmountLabel';
export const SELECT_PAYMENT_OTHER_AMOUNT_VALUE = 'SelectPayment.OtherAmountValue';
export const SELECT_PAYMENT_PRINCIPAL_ONLY_LABEL = 'SelectPayment.PrincipalOnlyLabel';
export const SELECT_PAYMENT_PRINCIPAL_ONLY_VALUE = 'SelectPayment.PrincipalOnlyValue';

// Payment confirmation
export const PAYMENT_CONFIRMATION_TITLE = 'PaymentConfirmation.Title';
export const PAYMENT_CONFIRMATION_INFO_LABEL = 'PaymentConfirmation.Info.Label';
export const PAYMENT_CONFIRMATION_AUTO_PAY_ENROLL_INFO_LABEL = 'PaymentConfirmation.AutoPayEnroll.Info.Label';
export const PAYMENT_CONFIRMATION_AUTO_PAY_ENROLL_LINK = 'PaymentConfirmation.AutoPayEnroll.Link';
export const PAYMENT_CONFIRMATION_SCHEDULED_PAYMENT_DATE_LABEL = 'PaymentConfirmation.ScheduledPaymentDate.Label';
export const PAYMENT_CONFIRMATION_DISCLAIMER_1_LABEL = 'PaymentConfirmation.Disclaimer.1.Label';
export const PAYMENT_CONFIRMATION_DISCLAIMER_2_LABEL = 'PaymentConfirmation.Disclaimer.2.Label';
export const PAYMENT_CONFIRMATION_NOTE_LABEL = 'PaymentConfirmation.Note.Label';
export const PAYMENT_CONFIRMATION_AUTOPAYENROLL_TITLE = 'PaymentConfirmation.AutopayEnroll.Title';
export const PAYMENT_CONFIRMATION_AUTOPAYENROLL_INFO = 'PaymentConfirmation.AutopayEnroll.Info';
export const PAYMENT_CONFIRMATION_AUTOPAYENROLL_PASTDUE_INFO = 'PaymentConfirmation.AutopayEnroll.Pastdue.Info';

// Add payment account
export const ERROR_VALID_ROUTING_NUMBER = 'Error.validRoutingNumber';
export const AUTO_PAY_INFO = 'Text.AutoPayInfoText';
export const ADD_PAYMENT_ALL_SET = 'AddPayment.AllSet.Label';
// Curtailment payments
export const CURTAILMENT_PAYMENTS_LOAN_PAYMENTS_SAVED = 'CurtailmentPayments.LoanPaymentsSaved';
export const CURTAILMENT_PAYMENTS_INTEREST_SAVED = 'CurtailmentPayments.InterestSaved';
export const CURTAILMENT_PAYMENTS_TOTAL_AMOUNT = 'CurtailmentPayments.TotalAmount';
export const CURTAILMENT_PAYMENTS_HEADER_LABEL = 'CurtailmentPayments.PrincipalPaymentDetails';
export const CURTAILMENT_PAYMENTS_HEADER_ACCOUNT_DETAILS = 'CurtailmentPayments.Header.AccountDetails';
export const CURTAILMENT_PAYMENTS_HEADER_CURRENT = 'CurtailmentPayments.Header.Current';
export const CURTAILMENT_PAYMENTS_HEADER_AFTER_PAYMENT = 'CurtailmentPayments.Header.AfterPayment';
export const CURTAILMENT_PAYMENTS_CURRENT_PRINCIPAL_BALANCE = 'CurtailmentPayments.CurrentPrincipalBalance';
export const CURTAILMENT_PAYMENTS_TOTAL_FUTURE_INTEREST = 'CurtailmentPayments.TotalFutureInterest';
export const CURTAILMENT_PAYMENTS_TOTAL_REMAINING_PAYMENTS = 'CurtailmentPayments.TotalRemainingPayments';

// Add Payment Confirmation
export const ADD_PAYMENT_ACCOUNT_SUCCESS_MESSAGE = 'AddPaymentAccount.SuccessMessage';
export const ADD_PAYMENT_ACCOUNT_AUTOPAY_CONTENT = 'AddPaymentAccount.AutoPay.Content';
export const ADD_PAYMENT_ACCOUNT_MAKEPAYMENT_CONTENT = 'AddPaymentAccount.MakePayment.Content';
export const ADD_PAYMENT_ACCOUNT_LOANPAYOFF_CONTENT = 'AddPaymentAccount.LoanPayoff.Content';
export const ADD_PAYMENT_ACCOUNT_LOANPAYOFF_DISCLAIMER = 'AddPaymentAccount.Loan.Payoff.Disclaimer';
export const ADD_PAYMENT_ACCOUNT_AUTOPAY_DISCLAIMER = 'AddPaymentAccount.AutoPay.Disclaimer';
export const ADD_PAYMENT_ACCOUNT_MAKEPAYMENT_DISCLAIMER = 'AddPaymentAccount.MakePayment.Disclaimer';
export const CURTAILMENT_PAYMENTS_CONFIRMATION = 'CurtailmentPayments.Confirmation';
export const ERROR_REQUIRED_ROUTING_NUMBER = 'Error.required_routingnumber';
export const ERROR_LENGTH_ROUTING_NUMBER = 'Error.length_routingnumber';
export const ERROR_INVALID_BANK_INFO = 'Error.invalidBankAcc';
export const ERROR_REQUIRED_ACCOUNT_NUMBER = 'Error.required_bankaccountnumber';
export const ERROR_REQUIRED_CONFIRM_ACCOUNT_NUMBER = 'Error.required_confirm_accountnumber';
export const ERROR_NUMERIC_ACCOUNT_NUMBER = 'Error.numeric_bankaccountnumber';
export const ERROR_REQUIRED_NAME = 'Error.required_nameonbankaccount';
export const ERROR_CHAR_NAME = 'Error.chars_nameonbankaccount';
export const ERROR_LENGTH_NAME = 'Error.maxlength_nameonbankaccount';
export const ERROR_PAYMENT_PROFILE_ALREADY_EXISTS = 'Error.payment_profile_already_exists';

// PAYOFFQUOTE FLOW

export const PAY_OFF_QUOTE_ELIGIBILITY_ERROR_PAGE = 'PayoffQuote.EligibilityErrorPage';
export const PAY_OFF_QUOTE_LEASE = 'PayoffQuote.LeaseFlow';
export const PAY_OFF_QUOTE_FLOW_HEADER = 'PayoffQuote.FlowHeader';
export const PAY_OFF_QUOTE_LOAN_MAIL_FLOW = 'PayoffQuote.LoanMailFlow';
export const PAY_OFF_QUOTE_PAYMENT_HEADER = 'PayoffQuote.LoanFlow.Payment';
export const PAY_OFF_QUOTE_SELECT_INFO = 'PayoffQuote.SelectInfo';
export const PAY_OFF_QUOTE_VERIFY_INFO = 'PayoffQuote.VerifyInfo';
export const PAY_OFF_QUOTE_VERIFY_OTHER_ADDRESS_INFO = 'PayoffQuote.Verify.Other.Address';
export const PAY_OFF_QUOTE_LOAN_PAYMENT_VERIFY_DETAILS = 'PayoffQuote.LoanFlow.Payment.Verify.Details';
export const PAY_OFF_QUOTE_LOAN_DELIVERY_LABEL = 'Payoff.Quote.Delivery.Label';
export const PAY_OFF_QUOTE_LOAN_DELIVERY_METHOD_LABEL = 'POQ.Delivery.Method.Label';
export const PAY_OFF_QUOTE_LOAN_TOOLTIP_TEXT = 'POQ.Loan.Tooltip.Text';
export const POQ_LOAN_PAYOFF_BILLING_ADDRESS_LABEL = 'POQ.Billing.Address.Label';
export const POQ_LOAN_PAYOFF_OTHER_ADDRESS_LABEL = 'POQ.Other.Address.Label';
export const POQ_LOAN_PAYOFF_RESIDENTIAL_ADDRESS1_LABEL = 'POQ.Residential.Address1.Label';
export const POQ_LOAN_PAYOFF_RESIDENTIAL_ADDRESS2_LABEL = 'POQ.Residential.Address2.Label';
export const POQ_LOAN_PAYOFF_CITY_LABEL = 'POQ.City.Label';
export const POQ_LOAN_PAYOFF_STATE_LABEL = 'POQ.State.Label';
export const POQ_LOAN_PAYOFF_ZIPCODE_LABEL = 'POQ.Zipcode.Label';
export const POQ_LOAN_PAYOFF_FULLNAME_LABEL = 'POQ.FullName.Label';
export const POQ_STATES = 'POQ.States';
export const POQ_FULLNAME_REQUIRED_ERROR = 'POQ.FullName.Required.Error';
export const POQ_RESIDENTIAL_REQUIRED_ERROR = 'POQ.Residential.Required.Error';
export const POQ_CITY_REQUIRED_ERROR = 'POQ.City.Required.Error';
export const POQ_STATE_REQUIRED_ERROR = 'POQ.State.Required.Error';
export const POQ_ZIPCODE_REQUIRED_ERROR = 'POQ.Zipcode.Required.Error';
export const POQ_FULLNAME_ERROR = 'POQ.FullName.Error';
export const POQ_RESIDENTIAL_ERROR = 'POQ.Residential.Error';
export const POQ_CITY_ERROR = 'POQ.City.Error';
export const POQ_ZIPCODE_ERROR = 'POQ.Zipcode.Error';
export const POQ_ZIPCODE_ERROR_FOR_SELECTED_STATE = 'POQ.Zipcode.Error.For.Selected.State';
export const POQ_MAX_LENGTH_ERROR = 'POQ.Max.Length.Error';
export const POQ_LOAN_PAYOFF_DELIVERY_USMAIL = 'POQ.Delivery.USmail';
export const POQ_LOAN_PAYOFF_DELIVERY_FEDEX = 'POQ.Delivery.Fedex';
export const POQ_LOAN_PAYOFF_DELIVERY_FEDEX_ACCOUNT = 'POQ.Delivery.Fedex.Account';
export const POQ_LOAN_PAYOFF_DELIVERY_FEDEX_LABLE = 'POQ.Delivery.Fedex.Lable';
export const POQ_LOAN_PAYOFF_DELIVERY_FEDEX_AUTHORIZATION = 'POQ.Delivery.FedexAuthorization';
export const POQ_LOAN_PAYOFF_DELIVERY_FEDEX_AUTHORIZATION_VW = 'POQ.Delivery.FedexAuthorization.VW';
export const POQ_LOAN_PAYOFF_DELIVERY_FEDEX_ACCOUNT_NUMBER = 'POQ.Delivery.FedexAccountNumber';
export const POQ_LOAN_PAYOFF_DELIVERY_FEDEX_POLICY = 'POQ.Delivery.FedexPolicy';
export const POQ_INVALID_ADDRESS_MESSAGE = 'POQ.Address.Invalid.Message';
export const POQ_INVALID_ADDRESS_MESSAGE_DESCRIPTION = 'POQ.Address.Invalid.Message.Description';
export const POQ_LOAN_PAYOFF_CONFIRM_ADDRESS_MESSAGE = 'POQ.Confirm.Address.Message';
export const POQ_LOAN_PAYOFF_ENTERED_ADDRESS_LABEL = 'POQ.Entered.Address.Label';
export const POQ_LOAN_PAYOFF_SUGGESTED_ADDRESS_LABEL = 'POQ.Suggested.Address.Label';
export const POQ_LOAN_PAYOFF_CONFIRM_ADDRESS_CHECK_MESSAGE = 'POQ.Confirm.Address.Check.Message';
export const POQ_LOAN_PAYOFF_ADDRESS_REENTER_MESSAGE = 'POQ.Address.Reenter.Message';
export const CANCEL_PAYOFF_QUOTE = 'Cancel.PayoffQuote';
export const PAY_OFF_QUOTE_CANCEL = 'PayoffQuote.Cancel';
export const PAY_OFF_AMOUNT = 'PayoffQuote.PayoffAmount';
export const GOOD_THROUGH_DATE = 'PayoffQuote.GoodThroughDate';
export const WITHDRAWAL_DATE = 'PayoffQuote.WithdrawalDate';
export const PAY_OFF_QUOTE_DETAILS = 'PayoffQuote.PayoffQuoteDetails';
export const PAY_OFF_AMOUNT_TOOL_TIP_DESCRIPTION = 'PayoffQuote.PayoffAmountToolTipDescription';
export const RESIDUAL_VALUE_TOOL_TIP_DESCRIPTION = 'PayoffQuote.ResidualValueToolTipDescription';
export const RESIDUAL_VALUE_NOTE_TOOL_TIP_DESCRIPTION = 'PayoffQuote.ResidualValueNoteToolTipDescription';
export const FEES_TOOL_TIP_DESCRIPTION = 'PayoffQuote.FeesToolTipDescription';
export const FEES_DESCRIPTION = 'PayoffQuote.Fees';
export const PAY_OFF_QUOTE_DISCLAIMER = 'PayoffQuote.Disclaimer';
export const PAY_OFF_QUOTE_TITLE = 'Loan.PayoffQuote';
export const PAY_OFF_QUOTE_CUSTOMER_ONLY_DISCLAIMER = 'POQ.Customer.Only.Disclaimer';
export const PAY_OFF_QUOTE_CUSTOMER_ONLY_CONTRACTED_DISCLAIMER = 'POQ.Customer.Only.Contracted.Disclaimer';
export const PAYOFF_QUOTE_RESIDUAL_VALUE = 'PayoffQuote.ResidualValue';
export const PAYOFF_QUOTE_PRINCIPAL_BALANCE = 'PayoffQuote.PrincipalBalance';
export const PAYOFF_QUOTE_INTEREST = 'PayoffQuote.Interest';
export const PAYOFF_QUOTE_RESIDUAL_TAX = 'PayoffQuote.ResidualTax';
export const PAYOFF_QUOTE_REMAINING_FUTURE_PAYMENT = 'PayoffQuote.RemainingFuturePayment';
export const PAYOFF_QUOTE_LATE_CHARGE = 'PayoffQuote.LateCharge';
export const PAYOFF_QUOTE_TAX_ON_FEES = 'PayoffQuote.TaxOnFees';
export const PAYOFF_QUOTE_PAYMENTS_DUE_NOT_PAID = 'PayoffQuote.PaymentDueNotPaid';
export const PAYOFF_QUOTE_PAST_DUE_TAX = 'PayoffQuote.PastDueTax';
export const PAYOFF_QUOTE_LATE_CHARGE_REBATE = 'PayoffQuote.LeaseChargeRebate';
export const PAYOFF_QUOTE_PURCHASE_OPTION_FEE = 'PayoffQuote.PurchaseOptionFee';
export const PAYOFF_QUOTE_ADMINISTRATIVE_FEE = 'PayoffQuote.AdministrativeFee';
export const PAYOFF_QUOTE_LEGAL_FEE = 'PayoffQuote.LegalFee';
export const PAYOFF_QUOTE_PAY_BY_PHONE_FEE = 'PayoffQuote.PayByPhoneFee';
export const PAYOFF_QUOTE_PAYMENT_EXTENSION_FEE = 'PayoffQuote.PaymentExtensionFee';
export const PAYOFF_QUOTE_VIOLATION_FEE = 'PayoffQuote.ViolationFee';
export const PAY_OFF_QUOTE_PROCESS_WITH_PAYOFF = 'PayoffQuote.ProcessWithPayoff';
export const PAY_OFF_QUOTE_LEASE_PACKET = 'PayoffQuote.LeasePacket';
export const PAY_OFF_QUOTE_LEASE_CONFIRMATION = 'PayoffQuote.LeaseConfirmation';
export const PAY_OFF_QUOTE_LOAN_CONFIRMATION = 'PayoffQuote.LoanConfirmation';
export const PAY_OFF_QUOTE_LOAN_CONFIRMATION_TEXT = 'PayoffQuote.LoanConfirmationText';
export const PAY_OFF_QUOTE_TITLE_MESSAGE_TITLE = 'PayoffQuote.Title.Message.Title';
export const PAY_OFF_QUOTE_TITLE_MESSAGE_CONTENT = 'PayoffQuote.Title.Message.Content';

export const POQ_LEASE_PACKET_SENT = 'PayoffQuote.LeasePacketSent';
export const POQ_LOAN_MAIL_PACKET_SENT = 'PayoffQuote.LoanMailPacketSent';
export const POQ_BALLOON_MAIL_PACKET_SENT = 'PayoffQuote.BalloonMailPacketSent';
export const PAY_OFF_QUOTE_PACKET_DECISION = 'PayoffQuote.LeasePacketDecision';
export const BACK_TO_QUOTE = 'PayoffQuote.BackToQuote';
export const POQ_LEASE_PAY_OFF_PACKET = 'PayoffQuote.Packet';
export const POQ_DOCUMENT_DETAILS_AUDI = 'PayoffQuote.AudiDocumentsDetails';
export const POQ_DOCUMENT_DETAILS_VW = 'PayoffQuote.VWDocumentsDetails';

export const POQ_DOCUMENT_DETAILS_BALLOON_MAIL_VW = 'PayoffQuote.VWDocumentsDetailsBalloonMail';
export const POQ_DOCUMENT_DETAILS_BALLOON_MAIL_AUDI = 'PayoffQuote.AudiDocumentsDetailsBalloonMail';

export const POQ_DOCUMENT_DETAILS_LOAN_MAIL_AUDI = 'PayoffQuote.AudiDocumentsDetailsLoanMail';
export const POQ_DOCUMENT_DETAILS_LOAN_MAIL_VW = 'PayoffQuote.VWDocumentsDetailsLoanMail';
export const POQ_DOCUMENT_CHECKLIST = 'PayoffQuote.DocumentChecklist';
export const POQ_VEHICLE_PAYOFF_INSTRUCTIONS = 'PayoffQuote.VehiclePayoffInstructions';
export const POQ_PAYOFF_QUOTE = 'PayoffQuote.PayoffQuote';
export const POQ_ODOMETER_STATEMENT = 'PayoffQuote.OdometerStatement';
export const POQ_BILL_OF_SALE = 'PayoffQuote.BillOfSale';
export const POQ_CHECK_WRITTEN_TO_VW = 'PayoffQuote.CheckWrittenToVW';
export const POQ_LEASE_CONFIRMATION_MESSAGE = 'PayoffQuote.ConfirmationMessage';
export const POQ_LOAN_MAIL_CONFIRMATION_MESSAGE = 'PayoffQuote.LoanMailConfirmationMessage';
export const POQ_BALLOON_MAIL_CONFIRMATION_MESSAGE = 'PayoffQuote.BalloonMailConfirmationMessage';
export const POQ_THANK_YOU_NOTE_AUDI = 'PayoffQuote.ThankYouNoteAudi';
export const POQ_THANK_YOU_NOTE_VW = 'PayoffQuote.ThankYouNoteVW';
export const POQ_VISIT_MESSAGE_CENTER = 'PayoffQuote.VisitMessageCenter';
export const POQ_POP_THANK_YOU_MESSAGE = 'PayoffQuote.PayoffPacketThankYouMessage';
export const POQ_POP_CONFIRMATION_MESSAGE = 'PayoffQuote.PayoffPacketConfirmationMessage';
export const POQ_LOAN_MAIL_POP_THANK_YOU_MESSAGE = 'PayoffQuote.PayoffPacketLoanMailThankYouMessage';
export const POQ_LOAN_MAIL_POP_CONFIRMATION_MESSAGE = 'PayoffQuote.PayoffPacketLoanMailConfirmationMessage';
export const POQ_BALLOON_MAIL_POP_THANK_YOU_MESSAGE = 'PayoffQuote.PayoffPacketBalloonMailThankYouMessage';
export const POQ_BALLOON_MAIL_POP_CONFIRMATION_MESSAGE = 'PayoffQuote.PayoffBalloonMailConfirmationMessage';
export const POQ_CONTACT_HEADER = 'PayoffQuote.AFSContactHeader';
export const POQ_CONTACT_HEAD_LINE_AUDI = 'PayoffQuote.AFSContactHeadLine';
export const POQ_CONTACT_HEAD_LINE_VW = 'PayoffQuote.VWContactHeadLine';
export const POQ_ADDRESS_TITLE = 'PayoffQuote.Verify.Adress.Title';
export const POQ_PAYMENT_METHOD_TITLE = 'PayoffQuote.Verify.Payment.Method.Title';
export const POQ_PAYMENT_CONFIRMATION_TITLE = 'PayoffQuote.Payment.Confirmation.Title';
export const POQ_NOELEGIBLE_PAYMENT_CONFIRMATION_TITLE = 'PayoffQuote.NoElegible.Payment.Confirmation.Title';
export const POQ_DELIVERY_ELECTRONIC_TITLE_MESSAGE_TITLE = 'PayoffQuote.Delivery.Electronic.Title.Message.Title';
export const POQ_DELIVERY_ELECTRONIC_TITLE_STATUS_MESSAGE_AUDI =
  'PayoffQuote.Delivery.Electronic.Title.Status.Message.Audi';
export const POQ_DELIVERY_ELECTRONIC_TITLE_STATUS_MESSAGE_VW =
  'PayoffQuote.Delivery.Electronic.Title.Status.Message.VW';
export const RK1 = 'PayoffQuote.RK1';
// dynamically referenced
export const RK2_VW = 'PayoffQuote.RK2.VW';
export const RK2_AUDI = 'PayoffQuote.RK2.AUDI';
export const RK3 = 'PayoffQuote.RK3';
export const RK4 = 'PayoffQuote.RK4';
export const RK5 = 'PayoffQuote.RK5';
export const RK6 = 'PayoffQuote.RK6';
export const RK7 = 'PayoffQuote.RK7';
export const RK8 = 'PayoffQuote.RK8';
export const POQ_UNAVAILABLE = 'PayoffQuote.POQUnavailable';
export const POQ_RK6_TITLE_HEADER = 'PayoffQuote.RK6.TitleHeader';
export const POQ_RK6_TITLE_SUB_HEADER = 'PayoffQuote.RK6.SubTitleHeader';
export const POQ_RK6_TITLE_CONTENT = 'PayoffQuote.RK6.Content';
export const POQ_RK6_ELECTRONIC_TITLE_HEADER = 'PayoffQuote.RK6.ElectronicTitleHeader';
export const POQ_RK6_ELECTRONIC_TITLE_CONTENT = 'PayoffQuote.RK6.ElectronicTitleContent';
export const POQ_RK7_TITLE_HEADER = 'PayoffQuote.RK7.TitleHeader';
export const POQ_RK7_TITLE_CONTENT = 'PayoffQuote.RK7.Content';
export const POQ_PAYMENT_ERROR_QUOTEID_REQUIRED = 'PayoffQuote.Payment.Error.QuoteID.Required';
export const POQ_PAYMENT_ERROR_ACCOUNT_NUMBER = 'PayoffQuote.Payment.Error.Account.Number';
export const POQ_PAYMENT_ERROR_EMPTY_PAYOFFAMOUNT = 'PayoffQuote.Payment.Error.Empty.PayoffAmount';
export const POQ_PAYMENT_ERROR_MIN_PAYOFFAMOUNT = 'PayoffQuote.Payment.Error.Min.PayoffAmount';
export const POQ_PAYMENT_ERROR_MAX_PAYOFFAMOUNT = 'PayoffQuote.Payment.Error.Max.PayoffAmount';
export const POQ_PAYMENT_ERROR_DUPLICATE_PAYMENT = 'PayoffQuote.Payment.Error.Duplicate.Payment';
export const POQ_PAYMENT_ERROR_DATE_INVALID = 'PayoffQuote.Payment.Error.Date.Invalid';
export const POQ_PAYMENT_ERROR_SOURCE_REQUIRED = 'PayoffQuote.Payment.Error.Source.Required';
export const POQ_PAYMENT_ERROR_USMAIL_FEEAMOUNT = 'PayoffQuote.Payment.Error.USMail.FeeAmount';
export const POQ_PAYMENT_ERROR_FEDEX_FEEAMOUNT = 'PayoffQuote.Payment.Error.FedEx.FeeAmount';
export const POQ_PAYMENT_ERROR_FEDEX_FEESHOULDBEZERO = 'PayoffQuote.Payment.Error.FedEx.FeeShouldBeZero';
export const POQ_PAYMENT_ERROR_ALTADDRESS_REQUIRED = 'PayoffQuote.Payment.Error.AltAddress.Required';
export const POQ_PAYMENT_ERROR_ADDRESSLINEONE_REQUIRED = 'PayoffQuote.Payment.Error.AddressLineOne.Required';
export const POQ_PAYMENT_ERROR_ADDRESSCITY_REQUIRED = 'PayoffQuote.Payment.Error.AddressCity.Required';
export const POQ_PAYMENT_ERROR_ADDRESSSTATE_REQUIRED = 'PayoffQuote.Payment.Error.AddressState.Required';
export const POQ_PAYMENT_ERROR_ADDRESSSTATE_INVALID = 'PayoffQuote.Payment.Error.AddressState.Invalid';
export const POQ_PAYMENT_ERROR_ADDRESSCOUNTRY_REQUIRED = 'PayoffQuote.Payment.Error.AddressCountry.Required';
export const POQ_PAYMENT_ERROR_ADDRESSPOSTALCODE_REQUIRED = 'PayoffQuote.Payment.Error.AddressPostalCode.Required';
export const POQ_PAYMENT_ERROR_DELIVERYINFO_REQUIRED = 'PayoffQuote.Payment.Error.DeliveryInfo.Required';
export const POQ_PAYMENT_ERROR_BILLINGADDRESSFLAG_REQUIRED = 'PayoffQuote.Payment.Error.BillingAddressFlag.Required';
export const POQ_PAYMENT_ERROR_MAILTYPE_REQUIRED = 'PayoffQuote.Payment.Error.MailType.Required';
export const POQ_PAYMENT_ERROR_FEDEXFEEOPTION_REQUIRED = 'PayoffQuote.Payment.Error.FedExFeeOption.Required';

// UTILITIES
export const UTILITIES_MONTHS = 'Utilities.Months';

// ContactUS
export const CONTACT_LABEL_AUDI = 'Contact.AFS.Header';
export const CONTACT_LABEL_VW = 'Contact.VW.Header';
export const GENERAL_CORRESPONDENCE_LABEL = 'General.Correspondence.Label';
export const ARIA_GENERAL_CORRESPONDENCE_LABEL = 'Aria.General.Correspondence.Label';
export const SUPPORT_LABEL = 'Support.Label';
export const MESSAGE_CENTER_LABEL = 'Message.Center.Label';
export const HELP_CENTER_LABEL = 'Help.Center.Label';
export const HELP_CENTER_ERROR_LABEL = 'Help.Center.Error.Label';
export const HELP_CENTER_TEXT = 'Help.Center.Text';
export const HELP_CENTER_LOADING_TEXT = 'Help.Center.Loading_Text';
export const HELP_CENTER_REMOTE_LOADING_TEXT = 'Help.Center.Remote.Loading.Text';
export const CONTACT_PHONE_NUMBER_AUDI = 'AFS.Contact.Phone.Number';
export const CONTACT_PHONE_NUMBER_VW = 'VW.Contact.Phone.Number';
export const CONTACT_BUSINESS_HOURS_AUDI = 'AFS.Contact.BusinessHours';
export const CONTACT_BUSINESS_HOURS_VW = 'VW.Contact.BusinessHours';
export const CONTACT_PHONE_NUMBER_LABEL_AUDI = 'AFS.Phone.Number';
export const CONTACT_PHONE_NUMBER_LABEL_VW = 'VW.Contact.Phone.Number';
export const MY_AUDI_MOBILE_APP_MESSAGE_CENTER = 'Text.myAudiAppMessageCenter';
// Headers
export const HEADERS_LANGUAGE = 'Headers.AcceptedLanguage';

// Errors
export const ERROR_NOTIFICATION_DASHBOARD_HEADER = 'ErrorNotification.Dashboard.Header';
export const ERROR_NOTIFICATION_DASHBOARD_CONTENT = 'ErrorNotification.Dashboard.Content';

// Cancel Payment Flow
export const PENDING_PAYMENT_CANCELLATION_TITLE = 'PendingPayment.Cancellation.Title';
export const PENDING_PAYMENT_CANCELLATION_CONFIRMATION_TITLE = 'PendingPayment.Cancellation.Confirmation.Title';
export const PENDING_PAYMENT_PAYOFF_CANCELLATION_TITLE = 'PendingPayment.Payoff.Cancellation.Title';
export const PENDING_PAYMENT_PAYOFF_CANCELLATION_CONFIRMATION_TITLE =
  'PendingPayment.Payoff.Cancellation.Confirmation.Title';
export const PENDING_PAYMENT_CANCEL_TEXT = 'PendingPayment.Cancel.Text';
export const PENDING_PAYOFF_PAYMENT_CANCEL_TEXT = 'PendingPayment.Payoff.Cancel.Text';
export const PENDING_PAYMENT_PAYMENT_DATE_LABEL = 'PendingPayment.PaymentDate.Label';
export const PENDING_PAYMENT_REFERENCE_NUMBER_LABEL = 'PendingPayment.ReferenceNumber.Label';
export const PENDING_PAYMENT_PAYMENT_AMOUNT_LABEL = 'PendingPayment.PaymentAmount.Label';
export const PENDING_PAYMENT_PAYMENT_ACCOUNT_LABEL = 'PendingPayment.PaymentAccount.Label';
export const PENDING_PAYMENT_DISCLAIMER_TEXT = 'PendingPayment.Disclaimer.Text';
export const PENDING_PAYMENT_CANCELLED_TEXT = 'PendingPayment.Cancelled.Text';
export const PENDING_PAYOFF_PAYMENT_CANCELLED_TEXT = 'PendingPayment.Payoff.Cancelled.Text';
export const ERROR_NOTIFICATION_SYSTEM_ERROR_HEADER = 'ErrorNotification.System.Error.Header';
export const ERROR_NOTIFICATION_SYSTEM_ERROR_TITLE = 'ErrorNotification.System.Error.Title';
export const ERROR_NOTIFICATION_SYSTEM_ERROR_CONTENT = 'ErrorNotification.System.Error.Content';
export const ERROR_NOTIFICATION_FEATURE_UNAVAILABLE = 'ErrorNotification.Feature.Unavailable';
export const INFO_NATURAL_DISASTER_TITLE = 'Info.NaturalDisasterTitle';
export const INFO_NATURAL_DISASTER_CONTENT_AUDI = 'Info.NaturalDisasterContent';
export const INFO_NATURAL_DISASTER_CONTENT_VW = 'VW.Info.NaturalDisasterContent';
export const INFO_NATURAL_DISASTER_SECURE_MESSAGE = 'Info.NaturalDisasterSecureMessage';
export const TEXT_OR_CALL = 'Text.OrCall';

// Account closed notification
export const ACCOUNT_CLOSED_NOTIFICATION_HEADER = 'AccountClosed.Notification.Header';
export const VW_ACCOUNT_CLOSED_NOTIFICATION_HEADER = 'VW.AccountClosed.Notification.Header';
export const ACCOUNT_CLOSED_NOTIFICATION_CONTENT = 'AccountClosed.Notification.Content';
export const VW_ACCOUNT_CLOSED_NOTIFICATION_CONTENT = 'VW.AccountClosed.Notification.Content';
export const ACCOUNT_INACTIVE_NOTIFICATION_HEADER = 'AccountInactive.Notification.Header';
export const VW_ACCOUNT_INACTIVE_NOTIFICATION_HEADER = 'VW.AccountInactive.Notification.Header';
export const ACCOUNT_INACTIVE_NOTIFICATION_CONTENT = 'AccountInactive.Notification.Content';
export const VW_ACCOUNT_INACTIVE_NOTIFICATION_CONTENT = 'VW.AccountInactive.Notification.Content';
export const ACCOUNT_INACTIVE_NOTIFICATION_MESSAGE = 'AccountInactive.Notification.Message';
export const ACCOUNT_INACTIVE_NOTIFICATION_TITLE_AUTOPAY = 'AccountInactive.Notification.Title.AutoPay';
export const ACCOUNT_INACTIVE_NOTIFICATION_TITLE_MAKE_PAYMENT = 'AccountInactive.Notification.Title.MakePayment';
export const ACCOUNT_INACTIVE_NOTIFICATION_TITLE_PAPERLESS_ENROLL =
  'AccountInactive.Notification.Title.PaperlessEnroll';
export const ACCOUNT_INACTIVE_NOTIFICATION_TITLE_PAYOFF_QUOTE = 'AccountInactive.Notification.Title.PayoffQuote';
export const VW_ACCOUNT_INACTIVE_NOTIFICATION_TITLE_AUTOPAY = 'VW.AccountInactive.Notification.Title.AutoPay';
export const ACCOUNT_INACTIVE_NOTIFICATION_MESSAGE_AUTOPAY = 'AccountInactive.Notification.Message.AutoPay';
export const ACCOUNT_INACTIVE_NOTIFICATION_MESSAGE_MAKE_PAYMENT = 'AccountInactive.Notification.Message.MakePayment';
export const ACCOUNT_INACTIVE_NOTIFICATION_MESSAGE_PAPERLESS_ENROLL =
  'AccountInactive.Notification.Message.PaperlessEnroll';
export const ACCOUNT_INACTIVE_NOTIFICATION_MESSAGE_PAYOFF_QUOTE = 'AccountInactive.Notification.Message.PayoffQuote';
export const VW_ACCOUNT_INACTIVE_NOTIFICATION_MESSAGE_AUTOPAY = 'VW.AccountInactive.Notification.Message.AutoPay';

export const ACCOUNT_CLOSED_NOTIFICATION_HEADER_AUDI_CANADA = 'AccountClosed.Notification.Header.Audi.Canada';
export const ACCOUNT_CLOSED_NOTIFICATION_CONTENT_AUDI_CANADA = 'AccountClosed.Notification.Content.Audi.Canada';
export const ACCOUNT_CLOSED_NOTIFICATION_HEADER_VW_CANADA = 'AccountClosed.Notification.Header.VW.Canada';
export const ACCOUNT_CLOSED_NOTIFICATION_CONTENT_VW_CANADA = 'AccountClosed.Notification.Content.VW.Canada';
export const ACCOUNT_INACTIVE_NOTIFICATION_HEADER_AUDI_CANADA = 'AccountInactive.Notification.Header.Audi.Canada';
export const ACCOUNT_INACTIVE_NOTIFICATION_CONTENT_AUDI_CANADA = 'AccountInactive.Notification.Content.Audi.Canada';
export const ACCOUNT_INACTIVE_NOTIFICATION_HEADER_VW_CANADA = 'AccountInactive.Notification.Header.VW.Canada';
export const ACCOUNT_INACTIVE_NOTIFICATION_CONTENT_VW_CANADA = 'AccountInactive.Notification.Content.VW.Canada';

// Vehicle information unavailable notification
export const VEHICLE_INFORMATION_UNAVAILABLE_HEADER = 'VehicleInfoUnavailable.Notification.Header';
export const VW_VEHICLE_INFORMATION_UNAVAILABLE_HEADER = 'VW.VehicleInfoUnavailable.Notification.Header';
export const VEHICLE_INFORMATION_UNAVAILABLE_CONTENT = 'VehicleInfoUnavailable.Notification.Content';
export const VW_VEHICLE_INFORMATION_UNAVAILABLE_CONTENT = 'VW.AccountInactive.Notification.Content';
export const VEHICLE_INFORMATION_UNAVAILABLE_MESSAGE = 'VehicleInfoUnavailable.Notification.Message';
export const VW_VEHICLE_INFORMATION_UNAVAILABLE_MESSAGE = 'VW.VehicleInfoUnavailable.Notification.Message';

// Session expiration notification
export const SESSION_TIMEOUT_NOTIFICATION_HEADER = 'SessionTimeout.Notification.Header';
export const SESSION_TIMEOUT_NOTIFICATION_CONTENT = 'SessionTimeout.Notification.Content';
export const SESSION_TIMEOUT_FA_NOTIFICATION_CONTENT = 'SessionTimeout.FA.Notification.Content';
export const SESSION_TIMEOUT_FA_NOTIFICATION_CONTENT_TWO = 'SessionTimeout.FA.Notification.Content.Two';

export const MAINTENANCE_NOTICE_HEADER = 'Maintenance.Notice.Header';
export const MAINTENANCE_NOTICE_CONTENT = 'Maintenance.Notice.Content';

// Contract Amortization
export const AMORTIZATION_STATEMENT_HEADER = 'Amortization.Statement.Header';
export const VIEW_STATEMENT_LINK = 'View.Statement.Link';
export const AMORTIZATION_STATEMENT_CONTENT = 'Amortization.Statement.Content';
export const AMORTIZATION_STATEMENT_CONTENT_NOTE = 'Amortization.Statement.Content.Note';
export const AMORTIZATION_PAYMENT_PAST_DUE_TITLE = 'Amortization.Statement.Payment.Past.Due.Title';
export const AMORTIZATION_PAYMENT_PAST_DUE_TEXT = 'Amortization.Statement.Payment.Past.Due.Text';

// Progress Tracker
export const PROGRESS_TRACKER_SUCCESS_HEADER = 'ProgressTracker.Success.Header';
export const PROGRESS_TRACKER_PENDING_HEADER = 'ProgressTracker.Pending.Header';
export const PROGRESS_TRACKER_CONTENT = 'ProgressTracker.Content';
export const PROGRESS_TRACKER_ERROR_HEADER = 'ProgressTracker.Error.Header';
export const PROGRESS_TRACKER_ERROR_CONTENT = 'ProgressTracker.Error.Content';
export const PROGRESS_TRACKER_ERROR_PLEASE_TRY_AGAIN = 'ProgressTracker.Error.PleaseTryAgain';
export const SITEHEADER_NO_ACCOUNT_EXIST = 'SiteHeader.No.Account.Return';
export const SITEHEADER_LOGOUT_LABEL = 'SiteHeader.Logout.Label';
export const SITEHEADER_HOME_LABEL = 'SiteHeader.Home.Label';
export const SITEHEADER_ACCOUNT_SETTING_LABEL = 'SiteHeader.Account.Setting.Label';
export const SITEHEADER_LANGUAGE_LABEL = 'SiteHeader.Language.Label';
export const SITEHEADER_CLOSE_MENU_LABEL = 'SiteHeader.Close.Menu.Label';
export const SITEHEADER_HELP_FOOTER_LABEL = 'SiteHeader.Help.Footer.Label';

// DueDate Changes - easysend
export const UPDATE_BILLING_DUE_DATE = 'Update.Billing.DueData.Label';
export const ELIGIBLE_DUEDATE_UPDATE_LABEL = 'Eligible.DueDate.Update.Label';
export const DUEDATE_UPDATE_NOW_LABEL = 'DueDate.Update.Label';

// VSP Tracker Tooltip
export const TRACKER_TOOLTIP_TITLE = 'Tracker.Tooltip.Title';
export const TRACKER_TOOLTIP_TEXT_1 = 'Tracker.Tooltip.P1';
export const TRACKER_TOOLTIP_TEXT_2 = 'Tracker.Tooltip.P2';
export const TRACKER_TOOLTIP_TEXT_1_VW = 'VW.Tracker.Tooltip.P1';
export const TRACKER_TOOLTIP_TEXT_2_VW = 'VW.Tracker.Tooltip.P2';
export const TRACKER_TOOLTIP_BUTTON_1 = 'Tracker.Tooltip.Button.1';
export const TRACKER_TOOLTIP_BUTTON_2 = 'Tracker.Tooltip.Button.2';

// Lease transition 6 month Tracker Tooltip
export const LEASE_TRANSITION_6_MONTH_TRACKER_TOOLTIP_TITLE = 'Lease.Transition.6.Month.Tracker.Tooltip.Title';
export const LEASE_TRANSITION_6_MONTH_TRACKER_TOOLTIP_TEXT = 'Lease.Transition.6.Month.Tracker.Tooltip.Text';
export const LEASE_TRANSITION_6_MONTH_TRACKER_TOOLTIP_TEXT_VW = 'Lease.Transition.6.Month.Tracker.Tooltip.Text.VW';
export const LEASE_TRANSITION_6_MONTH_TRACKER_TOOLTIP_BUTTON = 'Lease.Transition.6.Month.Tracker.Tooltip.Button';

// Lease transition 3 month Tracker Tooltip
export const LEASE_TRANSITION_3_MONTH_TRACKER_TOOLTIP_TITLE = 'Lease.Transition.3.Month.Tracker.Tooltip.Title';
export const LEASE_TRANSITION_3_MONTH_TRACKER_TOOLTIP_TITLE_VW = 'Lease.Transition.3.Month.Tracker.Tooltip.Title.VW';
export const LEASE_TRANSITION_3_MONTH_TRACKER_TOOLTIP_TITLE2_VW = 'Lease.Transition.3.Month.Tracker.Tooltip.Title2.VW';
export const LEASE_TRANSITION_3_MONTH_TRACKER_TOOLTIP_TEXT = 'Lease.Transition.3.Month.Tracker.Tooltip.Text';
export const LEASE_TRANSITION_3_MONTH_TRACKER_TOOLTIP_TEXT_VW = 'Lease.Transition.3.Month.Tracker.Tooltip.Text.VW';
export const LEASE_TRANSITION_3_MONTH_TRACKER_TOOLTIP_BUTTON = 'Lease.Transition.3.Month.Tracker.Tooltip.Button';
export const LEASE_TRANSITION_3_MONTH_TRACKER_TOOLTIP_BUTTON_VW = 'Lease.Transition.3.Month.Tracker.Tooltip.Button.VW';
export const LEASE_TRANSITION_3_MONTH_TRACKER_TOOLTIP_SILENCE_BUTTON =
  'Lease.Transition.3.Month.Tracker.Tooltip.Silence.Button';
export const LEASE_TRANSITION_3_MONTH_TRACKER_TOOLTIP_REMIND_BUTTON =
  'Lease.Transition.3.Month.Tracker.Tooltip.Silence.Remind';

// Due date on datepicker
export const DATEPICKER_FORMATTED_DUEDATE = 'DatePicker.Formatted.DueDate';

// Paperless
export const PAPERLESS_ESIGN_TEXT1 = 'Paperless.ESign.Text1';
export const PAPERLESS_ESIGN_TEXT2 = 'Paperless.ESign.Text2';
export const PAPERLESS_ESIGN_TEXT3 = 'Paperless.ESign.Text3';
export const PAPERLESS_AGREEMENT_TEXT = 'Paperless.Agreement.Text';
export const PAPERLESS_NOTIFICATION_TEXT1 = 'Paperless.Notification.Text1';
export const PAPERLESS_NOTIFICATION_TEXT2 = 'Paperless.Notification.Text2';
export const PAPERLESS_NOTIFICATION_TITLE = 'Paperless.Notification.Title';
export const PAPERLESS_NOTIFICATION_HEADER = 'Paperless.Notification.Header';
export const PAPERLESS_CANCELLATION_CONFIRMATION_TEXT = 'Text.CancelConfirmationPaperless';
export const PAPERLESS_CANCELLATION_CONFIRMATION_ENROLL_TEXT = 'Text.CancelConfirmationPaperlessEnroll';
export const PAPERLESS_SUCCESS_MESSAGE = 'Paperless.Success.Message';
export const PAPERLESS_CONFIRMATION_CONTENT = 'Paperless.Confirmation.Text1';
export const PAPERLESS_CONFIRMATION_TEXT = 'Paperless.Confirmation.Text2';
export const PAPERLESS_CONFIRMATION_HEADER = 'Paperless.Confirmation.Header';
// EOT Payment Title
export const EOT_PAYMENT_TITLE = 'EOTPaymentDetails.Title';

// EOT Payment Details
export const EOT_PAYMENT_DETAILS_FORM_TITLE = 'EOTPaymentDetails.Form.Title';
export const EOT_PAYMENT_DETAILS_AMOUNT_OWED_TOOLTIP = 'EOTPaymentDetails.AmountOwed.Tooltip';
export const EOT_PAYMENT_DETAILS_AMOUNT_OWED_TOOLTIP_AUDI = 'EOTPaymentDetails.AmountOwed.Tooltip.Audi';
export const EOT_SELECT_PAYMENT_TYPE_AMOUNT_OWED_TOOLTIP = 'EOT.SelectPaymentType.AmountOwed.Tooltip';
export const EOT_SELECT_PAYMENT_TYPE_OTHER_AMOUNT_TOOLTIP = 'EOT.SelectPaymentType.OtherAmount.Tooltip';
export const EOT_PAYMENT_DETAILS_OTHER_AMOUNT_ERROR = 'EOTPaymentDetails.OtherAmount.Error';
export const EOT_PAYMENT_DETAILS_OTHER_AMOUNT_REMAINING_BALANCE_ERROR =
  'EOTPaymentDetails.OtherAmount.RemainingBalance.Error';
export const EOT_PAYMENT_DETAILS_FORM_LABEL1 = 'EOTPaymentDetails.Form.Label1';
export const EOT_PAYMENT_DETAILS_FORM_LABEL2 = 'EOTPaymentDetails.Form.Label2';
export const EOT_PAYMENT_DETAILS_FORM_LABEL3 = 'EOTPaymentDetails.Form.Label3';
export const EOT_PAYMENT_DETAILS_FORM_LABEL4 = 'EOTPaymentDetails.Form.Label4';
export const EOT_PAYMENT_DETAILS_FORM_LABEL5 = 'EOTPaymentDetails.Form.Label5';
export const EOT_PAYMENT_DETAILS_FORM_LABEL6 = 'EOTPaymentDetails.Form.Label6';
export const EOT_PAYMENT_DETAILS_FORM_LABEL7 = 'EOTPaymentDetails.Form.Label7';
export const EOT_PAYMENT_DETAILS_FORM_LABEL8 = 'EOTPaymentDetails.Form.Label8';
export const EOT_PAYMENT_DETAILS_FORM_LABEL9 = 'EOTPaymentDetails.Form.Label9';
export const EOT_PAYMENT_DETAILS_FORM_LABEL10 = 'EOTPaymentDetails.Form.Label10';
export const EOT_PAYMENT_DETAILS_FORM_LABEL11 = 'EOTPaymentDetails.Form.Label11';
export const EOT_PAYMENT_DETAILS_FORM_PLACEHOLDER1 = 'EOTPaymentDetails.Form.PlaceHolder1';
export const EOT_PAYMENT_DETAILS_FORM_PLACEHOLDER2 = 'EOTPaymentDetails.Form.PlaceHolder2';
export const EOT_PAYSUMMARY_NOTE1 = 'EOTPaymentSummary.NOTE1';
export const EOT_PAYSUMMARY_NOTE2 = 'EOTPaymentSummary.NOTE2';
export const EOT_PAYSUMMARY_NAMEONCARD_LABLE = 'EOTPaymentSummary.NameOnCard.Lable';
export const EOT_PAYSUMMARY_LINK_EDIT_DETAILS = 'EOTPaymentSummary.Link.EditDetails';
export const EOT_PAYSUMMARY_LABEL_CREDIT_CARD = 'EOTPaymentSummary.Label.CreditCard';
export const EOT_PAYSUMMARY_LABEL_ENDING_IN = 'EOTPaymentSummary.Label.EndingIn';

export const EOT_PAYMENT_DETAILS_FORM_ERROR1 = 'EOTPaymentDetails.Form.Error1';
export const EOT_PAYMENT_DETAILS_FORM_ERROR2 = 'EOTPaymentDetails.Form.Error2';
export const EOT_PAYMENT_DETAILS_FORM_ERROR3 = 'EOTPaymentDetails.Form.Error3';
export const EOT_PAYMENT_DETAILS_FORM_ERROR4 = 'EOTPaymentDetails.Form.Error4';
export const EOT_PAYMENT_DETAILS_FORM_ERROR5 = 'EOTPaymentDetails.Form.Error5';
export const EOT_PAYMENT_DETAILS_FORM_ERROR6 = 'EOTPaymentDetails.Form.Error6';
export const EOT_PAYMENT_DETAILS_FORM_ERROR7 = 'EOTPaymentDetails.Form.Error7';
export const EOT_PAYMENT_DETAILS_FORM_ERROR8 = 'EOTPaymentDetails.Form.Error8';
export const EOT_PAYMENT_DETAILS_FORM_ERROR9 = 'EOTPaymentDetails.Form.Error9';
export const EOT_PAYMENT_DETAILS_FORM_ERROR10 = 'EOTPaymentDetails.Form.Error10';
export const EOT_PAYMENT_DETAILS_FORM_ERROR11 = 'EOTPaymentDetails.Form.Error11';
export const EOT_PAYMENT_DETAILS_FORM_ERROR12 = 'EOTPaymentDetails.Form.Error12';
export const EOT_PAYMENT_DETAILS_CARD_TYPE1 = 'EOTPaymentDetails.Card.Type1';
export const EOT_PAYMENT_DETAILS_CARD_TYPE2 = 'EOTPaymentDetails.Card.Type2';
export const EOT_PAYMENT_DETAILS_CARD_TYPE3 = 'EOTPaymentDetails.Card.Type3';
export const EOT_PAYMENT_DETAILS_CARD_TYPE4 = 'EOTPaymentDetails.Card.Type4';

// EOT Payment Processing
export const EOT_PAYMENT_PROCESSING_NOTIFICATION = 'EOTPaymentProcessing.Notification';
export const EOT_PAYMENT_PROCESSING_LABEL1 = 'EOTPaymentProcessing.Label1';
export const EOT_PAYMENT_PROCESSING_LABEL2 = 'EOTPaymentProcessing.Label2';
export const EOT_PAYMENT_PROCESSING_LABEL3 = 'EOTPaymentProcessing.Label3';
export const EOT_PAYMENT_PROCESSING_DISCLAIMER_TEXT1 = 'EOTPaymentProcessing.Disclaimer1';
export const EOT_PAYMENT_PROCESSING_DISCLAIMER_TEXT2 = 'EOTPaymentProcessing.Disclaimer2';
export const EOT_PAYMENT_PROCESSING_TEXT1 = 'EOTPaymentProcessing.Text1';
export const EOT_PAYMENT_PROCESSING_TEXT2 = 'EOTPaymentProcessing.Text2';
export const EOT_PAYMENT_PROCESSING_TEXT3 = 'EOTPaymentProcessing.Text3';
export const EOT_PAYMENT_PROCESSING_TEXT4 = 'EOTPaymentProcessing.Text4';
export const EOT_PAYMENT_ERROR_DECLINE_API_HEADER = 'EOTPaymentProcessing.Error.DeclineApi.Header';
export const EOT_PAYMENT_ERROR_DECLINE_API_CONTENT = 'EOTPaymentProcessing.Error.DeclineApi.Content';

// Title text
export const VW_CREDIT_MY_ACCOUNT = 'VW.Credit.My.Account';
export const VW_FINANCE_MY_ACCOUNT = 'VW.Finance.My.Account';
export const AUDI_FINANCIAL_SERVICES_TITLE = 'Audi.Financial.Services';
export const AUDI_FINANCE_MY_ACCOUNT = 'Audi.Finance.My.Account';
export const DEFAULT_TITLE_TEXT = 'Default.Title.Text';

export const POQ_LOAN_CANADA_TEXT1 = 'Poq.loan.canada.text1';
export const POQ_LOAN_CANADA_TEXT2 = 'Poq.loan.canada.text2';
export const POQ_LOAN_CANADA_TEXT_VW = 'Poq.loan.canada.text.VW';
export const POQ_LOAN_CANADA_TEXT_AUDI = 'Poq.loan.canada.text';
export const VW_FINANCE_TEXT = 'vw.finance.text';
export const AUDI_FINANCE_TEXT = 'audi.finance.text';
export const ADDRESS_LINE1 = 'address.line1';
export const ADDRESS_LINE2 = 'address.line2';
export const ADDRESS_LINE3 = 'address.line3';

export const BALLOON_MESSAGING_HEADER_AUDI = 'balloon.messaging.header.audi';
export const BALLOON_MESSAGING_HEADER_VW = 'balloon.messaging.header.vw';
export const BALLOON_MESSAGING_CONTENT_TEXT1 = 'balloon.messaging.content.text1';

export const DUEDATE_TITLE = 'dueDate.title';
export const DUEDATE_BUTTON_AUDI = 'dueDate.button.text.audi';
export const OFA_TITLE = 'ofa.title';
export const OFA_BUTTON_AUDI = 'ofa.button.text.audi';
export const DUEDATE_BUTTON_VW = 'dueDate.button.text.vw';
export const OFA_BUTTON_VW = 'ofa.button.text.vw';
export const BALLOON_MESSAGING_CONTENT_TEXT2 = 'balloon.messaging.content.text2';
export const BALLOON_MESSAGING_CONTENT_TEXT3 = 'balloon.messaging.content.text3';
export const BALLOON_MESSAGING_FAQ_TEXT = 'balloon.messaging.faq.text';
export const BALLOON_MESSAGING_FAQ_LINK_TEXT = 'balloon.messaging.faq.link';
export const BALLOON_MESSAGING_FAQ_TEXT2 = 'balloon.messaging.faq.text2';
export const BALLOON_MESSAGING_BUTTON_TEXT = 'baloon.messaging.button.text';
export const BALLOON_MESSAGING_FAQ_ERROR_MESSAGE = 'faq.error.message';

// Account settings
export const ACCOUNT_SETTINGS_BUTTON = 'account.settings.button';
export const ACCOUNT_SETTINGS_BUTTON_TEXT = 'account.settings.button.text';
export const ACCOUNT_SETTINGS_ERROR_MESSAGE = 'account.settings.error.message';

// Title release Banner
export const TITLE_RELEASE_BANNER_TITLE = 'TitleRelease.Banner.Title';
export const TITLE_RELEASE_BANNER_CONTENT_1 = 'TitleRelease.Banner.Content1';
export const TITLE_RELEASE_BANNER_CONTENT_2 = 'TitleRelease.Banner.Content2';
export const TITLE_RELEASE_BANNER_CONTENT_3 = 'TitleRelease.Banner.Content3';
export const TITLE_RELEASE_BANNER_CONTENT_HELP_CENTER_LINK = 'TitleRelease.Banner.HelpCenterLink';
export const TITLE_RELEASE_BANNER_FAQ_CONTENT = 'TitleRelease.Banner.FAQContent';
export const TITLE_RELEASE_BANNER_FAQ_LINK = 'TitleRelease.Banner.FAQLink';

// Late Payment Banner
export const LATE_PAYMENT_BANNER_TITLE = 'LatePayment.Banner.Title';
export const LATE_PAYMENT_BANNER_CONTENT1 = 'LatePayment.Banner.Content1';
export const LATE_PAYMENT_BANNER_LINK = 'LatePayment.Banner.Link';
export const LATE_PAYMENT_BANNER_CONTENT2 = 'LatePayment.Banner.Content2';

// Payment Banner
export const PAYMENT_BANNER_TITLE1 = 'Payment.Banner.Title1';
export const PAYMENT_BANNER_TITLE2 = 'Payment.Banner.Title2';
export const PAYMENT_BANNER_TITLE3 = 'Payment.Banner.Title3';
export const PAYMENT_BANNER_LINK = 'Payment.Banner.Link';

// Payment confirmation Banner
export const PAYMENTCONF_BANNER_TITLE = 'PaymentConfirmation.Banner.Title';
export const PAYMENTCONF_BANNER_DESC1 = 'PaymentConfirmation.Banner.desc1';
export const PAYMENTCONF_BANNER_DESC2 = 'PaymentConfirmation.Banner.desc2';
export const PAYMENTCONF_BANNER_LINK = 'PaymentConfirmation.Banner.Link';

// account setting back link
export const ACCOUNT_SETTING_BACK_LINK_TEXT = 'AccountSettings.Back.Link';
export const VW_FINANCE_ACCOUNT_SETTING_BACK_OPTION_TEXT = 'VWFinance.AccountSettings.Back.Option';
export const VW_PLUS_ACCOUNT_SETTING_BACK_OPTION_TEXT = 'VWPlus.AccountSettings.Back.Option';
export const BACK_LINK_ACCOUNT_SETTING_BACK_LINK_TEXT = 'AccountSettings.Back.Link.Text';
export const BACK_LINK_ACCOUNT_SETTING_OPTION = 'AccountSettings.Back.Option';
export const ACCOUNT_SETTING_BACK_DEFAULT_OPTION_TEXT = 'AccountSettings.Back.Default.Option';

// Six month lease timeline

export const SIX_MONTH_LEASE_TIMELINE_TITLE = 'Six.Month.Lease.Timeline.Title';
export const SIX_MONTH_LEASE_TIMELINE_TEXT_1 = 'Six.Month.Lease.Timeline.Text1';
export const SIX_MONTH_LEASE_TIMELINE_SUBHEADER_1 = 'Six.Month.Lease.Timeline.Subheader1';
export const SIX_MONTH_LEASE_TIMELINE_TEXT_2 = 'Six.Month.Lease.Timeline.Text2';
export const SIX_MONTH_LEASE_TIMELINE_SUBHEADER_2 = 'Six.Month.Lease.Timeline.Subheader2';
export const SIX_MONTH_LEASE_TIMELINE_TEXT_3 = 'Six.Month.Lease.Timeline.Text3';
export const SIX_MONTH_LEASE_TIMELINE_SUBHEADER_3 = 'Six.Month.Lease.Timeline.Subheader3';
export const SIX_MONTH_LEASE_TIMELINE_TEXT_4 = 'Six.Month.Lease.Timeline.Text4';
export const SIX_MONTH_LEASE_TIMELINE_SUBHEADER_4 = 'Six.Month.Lease.Timeline.Subheader4';
export const SIX_MONTH_LEASE_TIMELINE_TEXT_5 = 'Six.Month.Lease.Timeline.Text5';
export const SIX_MONTH_LEASE_TIMELINE_RENEWAL_AUDI_TEXT_5 = 'Six.Month.Lease.Timeline.Renewal.Audi.Text5';
export const SIX_MONTH_LEASE_TIMELINE_RENEWAL_VW6_TEXT_5 = 'Six.Month.Lease.Timeline.Renewal.VW.Text5';
export const SIX_MONTH_LEASE_TIMELINE_SUBHEADER_5 = 'Six.Month.Lease.Timeline.Subheader5';
export const SIX_MONTH_LEASE_TIMELINE_TEXT_6 = 'Six.Month.Lease.Timeline.Text6';
export const SIX_MONTH_LEASE_TIMELINE_RENEWAL_AUDI_TEXT_6 = 'Six.Month.Lease.Timeline.Renewal.Audi.Text6';
export const SIX_MONTH_LEASE_TIMELINE_RENEWAL_VW6_TEXT_6 = 'Six.Month.Lease.Timeline.Renewal.VW.Text6';
export const SIX_MONTH_LEASE_TIMELINE_TEXT_7 = 'Six.Month.Lease.Timeline.Text7';
export const SIX_MONTH_LEASE_TIMELINE_TEXT_7_LINK = 'Six.Month.Lease.Timeline.Text7.Link';
export const SIX_MONTH_LEASE_TIMELINE_TEXT_7_AUDI = 'Six.Month.Lease.Timeline.Text7.AUDI';
export const SIX_MONTH_LEASE_TIMELINE_TEXT_7_VW = 'Six.Month.Lease.Timeline.Text7.VW';
export const SIX_MONTH_LEASE_TIMELINE_BUTTON = 'Six.Month.Lease.Timeline.Button';

// Three months lease transition options

export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_TITLE = 'Three.Month.Lease.Transition.Options.Title';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_SUBHEADER1 = 'Three.Month.Lease.Transition.Options.Subheader1';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_NUMERATION1_NUMBER =
  'Three.Month.Lease.Transition.Options.Numeration1.Number';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_NUMERATION1_TEXT =
  'Three.Month.Lease.Transition.Options.Numeration1.Text';
export const THREE_MONTH_LEASE_TRANSITION_RENEWAL_OPTIONS_NUMERATION1_TEXT =
  'Three.Month.Lease.Transition.Renewal.Options.Numeration1.Text';
export const THREE_MONTH_LEASE_TRANSITION_RENEWAL_OPTIONS_NUMERATION1_NOTE_QUESTION =
  'Three.Month.Lease.Transition.Renewal.Options.Numeration1.Note.Question';
export const THREE_MONTH_LEASE_TRANSITION_RENEWAL_OPTIONS_NUMERATION1_NOTE_ANSWER =
  'Three.Month.Lease.Transition.Renewal.Options.Numeration1.Note.Answer';
export const THREE_MONTH_LEASE_TRANSITION_RENEWAL_OPTIONS_NUMERATION1_BUTTON =
  'Three.Month.Lease.Transition.Renewal.Options.Numeration1.Button';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_NUMERATION2_NUMBER =
  'Three.Month.Lease.Transition.Options.Numeration2.Number';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_NUMERATION2_TEXT =
  'Three.Month.Lease.Transition.Options.Numeration2.Text';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_NUMERATION3_NUMBER =
  'Three.Month.Lease.Transition.Options.Numeration3.Number';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_NUMERATION3_TEXT =
  'Three.Month.Lease.Transition.Options.Numeration3.Text';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_SUBHEADER2 = 'Three.Month.Lease.Transition.Options.Subheader2';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_BULLETPOINT1_TITLE =
  'Three.Month.Lease.Transition.Options.BulletPoint1.Title';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_BULLETPOINT1_TEXT =
  'Three.Month.Lease.Transition.Options.BulletPoint1.Text';
export const THREE_MONTH_LEASE_TRANSITION_RENEWAL_OPTIONS_BULLETPOINT1_TEXT1 =
  'Three.Month.Lease.Transition.Renewal.Options.BulletPoint1.Text1';
export const THREE_MONTH_LEASE_TRANSITION_RENEWAL_OPTIONS_BULLETPOINT1_TEXT2 =
  'Three.Month.Lease.Transition.Renewal.Options.BulletPoint1.Text2';
export const THREE_MONTH_LEASE_TRANSITION_RENEWAL_OPTIONS_BULLETPOINT1_TEXT3 =
  'Three.Month.Lease.Transition.Renewal.Options.BulletPoint1.Text3';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_BULLETPOINT1_BUTTON =
  'Three.Month.Lease.Transition.Options.BulletPoint1.Button';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_BULLETPOINT2_TITLE =
  'Three.Month.Lease.Transition.Options.BulletPoint2.Title';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_BULLETPOINT2_TEXT =
  'Three.Month.Lease.Transition.Options.BulletPoint2.Text';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_BULLETPOINT3_TITLE =
  'Three.Month.Lease.Transition.Options.BulletPoint3.Title';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_BULLETPOINT3_TEXT =
  'Three.Month.Lease.Transition.Options.BulletPoint3.Text';
export const THREE_MONTH_LEASE_TRANSITION_RENEWAL_OPTIONS_BULLETPOINT3_TEXT =
  'Three.Month.Lease.Transition.Renewal.Options.BulletPoint3.Text';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_BULLETPOINT4_TITLE =
  'Three.Month.Lease.Transition.Options.BulletPoint4.Title';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_BULLETPOINT4_TEXT1 =
  'Three.Month.Lease.Transition.Options.BulletPoint4.Text1';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_BULLETPOINT4_TEXT2 =
  'Three.Month.Lease.Transition.Options.BulletPoint4.Text2';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_BULLETPOINT4_LINK =
  'Three.Month.Lease.Transition.Options.BulletPoint4.Link';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_BULLETPOINT5_TITLE =
  'Three.Month.Lease.Transition.Options.BulletPoint5.Title';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_BULLETPOINT5_TEXT1 =
  'Three.Month.Lease.Transition.Options.BulletPoint5.Text1';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_BULLETPOINT5_TEXT2 =
  'Three.Month.Lease.Transition.Options.BulletPoint5.Text2';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_BULLETPOINT5_LINK =
  'Three.Month.Lease.Transition.Options.BulletPoint5.Link';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_PARAGRAPH_TEXT1 =
  'Three.Month.Lease.Transition.Options.Paragraph.Text1';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_PARAGRAPH_TEXT2_AUDI =
  'Three.Month.Lease.Transition.Options.Paragraph.Text2.Audi';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_PARAGRAPH_TEXT2_VW =
  'Three.Month.Lease.Transition.Options.Paragraph.Text2.VW';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_PARAGRAPH_LINK =
  'Three.Month.Lease.Transition.Options.Paragraph.Link';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS_PARAGRAPH_BUTTON =
  'Three.Month.Lease.Transition.Options.Paragraph.Button';

// Due date change
export const DUE_DATE_CHANGE_LINK = 'Due.Date.Change.Link';
export const MESSAGE_LINK_TEXT = 'MessageCenter.remoteLink';
export const MESSAGE_LINK_PARAGRAPGH = 'MessageCenter.remoteLink.paragrapgh';
export const MESSAGE_WITH_DOC_CENTER_LINK_PARAGRAPGH = 'MessageCenterWithDocCenter.remoteLink.paragrapgh';
export const THREE_MONTH_LEASE_TRANSITION_RENEWAL_JUMPSTART_TITLE =
  'Three.Month.Lease.Transition.Renewal.JumpStart.Title';
export const THREE_MONTH_LEASE_TRANSITION_RENEWAL_JUMPSTART_NUMERATION1_NUMBER =
  'Three.Month.Lease.Transition.Renewal.JumpStart.Numeration1.Number';
export const THREE_MONTH_LEASE_TRANSITION_RENEWAL_JUMPSTART_NUMERATION1_SUBHEADER =
  'Three.Month.Lease.Transition.Renewal.JumpStart.Numeration1.Subheader';
export const THREE_MONTH_LEASE_TRANSITION_RENEWAL_JUMPSTART_NUMERATION1_TEXT =
  'Three.Month.Lease.Transition.Renewal.JumpStart.Numeration1.Text';
export const THREE_MONTH_LEASE_TRANSITION_RENEWAL_JUMPSTART_NUMERATION2_NUMBER =
  'Three.Month.Lease.Transition.Renewal.JumpStart.Numeration2.Number';
export const THREE_MONTH_LEASE_TRANSITION_RENEWAL_JUMPSTART_NUMERATION2_SUBHEADER =
  'Three.Month.Lease.Transition.Renewal.JumpStart.Numeration2.Subheader';
export const THREE_MONTH_LEASE_TRANSITION_RENEWAL_JUMPSTART_NUMERATION2_TEXT =
  'Three.Month.Lease.Transition.Renewal.JumpStart.Numeration2.Text';
export const THREE_MONTH_LEASE_TRANSITION_RENEWAL_JUMPSTART_NUMERATION3_NUMBER =
  'Three.Month.Lease.Transition.Renewal.JumpStart.Numeration3.Number';
export const THREE_MONTH_LEASE_TRANSITION_RENEWAL_JUMPSTART_NUMERATION3_SUBHEADER =
  'Three.Month.Lease.Transition.Renewal.JumpStart.Numeration3.Subheader';
export const THREE_MONTH_LEASE_TRANSITION_RENEWAL_JUMPSTART_NUMERATION3_TEXT =
  'Three.Month.Lease.Transition.Renewal.JumpStart.Numeration3.Text';
export const THREE_MONTH_LEASE_TRANSITION_RENEWAL_JUMPSTART_BUTTON =
  'Three.Month.Lease.Transition.Renewal.JumpStart.Button';
export const THREE_MONTH_LEASE_TRANSITION_RENEWAL_JUMPSTART_NOTE =
  'Three.Month.Lease.Transition.Renewal.JumpStart.Note';
export const THREE_MONTH_LEASE_TRANSITION_OPTIONS = 'Three.Month.Lease.Transition.Options';
export const THREE_MONTH_LEASE_TRANSITION_JUMP_START = 'Three.Month.Lease.Transition.JumpStart';
export const THREE_MONTH_LEASE_TRANSITION_ERROR = 'Three.Month.Lease.Transition.Error';
export const LEASE_RENEWAL_CANCEL_FLOW_VW_LOGO = 'Lease.Renewal.Cancel.Flow.Vw.Logo';
export const LEASE_RENEWAL_CANCEL_FLOW_TITLE = 'Lease.Renewal.Cancel.Flow.Title';
export const LEASE_RENEWAL_CANCEL_FLOW_TEXT = 'Lease.Renewal.Cancel.Flow.Text';
export const LEASE_RENEWAL_CANCEL_FLOW_BUTTON_YES = 'Lease.Renewal.Cancel.Flow.Button.Yes';
export const LEASE_RENEWAL_CANCEL_FLOW_BUTTON_NO = 'Lease.Renewal.Cancel.Flow.Button.No';

// CDK Outage Banner
export const CDK_OUTAGE_BANNER_TITLE = 'CDK.Outage.Banner.Title';
export const CDK_OUTAGE_BANNER_DESCRIPTION = 'CDK.Outage.Banner.Description';
export const CDK_OUTAGE_BANNER_LINK = 'CDK.Outage.Banner.Link';

// Feature not supported banner in transaction tabs
export const FEATURE_NOT_SUPPORTED_BANNER_MYAUDI_CONTENT_ONE = 'FeatureNotSupportedBanner.MyAudi.Content.One';
export const FEATURE_NOT_SUPPORTED_BANNER_MYAUDI_CONTENT_TWO = 'FeatureNotSupportedBanner.MyAudi.Content.Two';
