/** Get, Set, Remove and ClearALl actions on localstorage */

/**
 * Simple set data on localstorage
 * @param key
 * @param value
 */
export const set = (key, value) => {
  localStorage.setItem(key, value);
};
/**
 * Get item by key from localstorage
 * @param key the key
 * @returns {string} the value
 */
export const get = (key) => {
  return localStorage?.getItem(key);
};

/**
 * Remove item by key from localstorage
 * @param key the key
 */
export const remove = (key) => {
  localStorage.removeItem(key);
};

/**
 * Clear all from localstorage
 */
export const clearAll = () => {
  localStorage.clear();
};
