/**
 * Valid message types between parent and child component
 *
 */
export const MessageTypes = /** @type {const} */ ({
  DASHBOARD_VISIT: 'dashboardVisit',
  MESSAGE_CENTER_VISIT: 'messageCenterVisit',
  AUTH_ERROR: 'authError',
  AUTH_SUCCESS: 'authSuccess',
  AUTH_TIMEOUT: 'authTimeout',

  MULTIPLE_ACCOUNT_WARNING: 'multiAccountWarning',

  RENDER_COMPLETE: 'renderComplete',
  LOCAL_STORAGE_ACCESSIBLE: 'localStorageAccessible',
  LOCAL_STORAGE_BLOCKED: 'localStorageBlocked',

  ACCOUNT_LOAD_SUCCESS: 'accountLoadSuccess',
  ACCOUNT_LOAD_FAIL: 'accountLoadFail',

  PAYMENT_STARTED: 'paymentStarted',
  PAYMENT_COMPLETE: 'paymentComplete',
  PAYMENT_CANCELLED: 'paymentCancelled',
  PAYMENT_ERROR: 'paymentError',
  PAYMENT_SURVEY_STARTED: 'paymentSurveyStarted',
  PAYMENT_SURVEY_COMPLETE: 'paymentSurveyComplete',

  CANCEL_PAYMENT_STARTED: 'CancelPaymentStarted',
  CANCEL_PAYMENT_COMPLETE: 'CancelPaymentComplete',
  CANCEL_PAYMENT_CANCELLED: 'CancelPaymentCancelled',
  CANCEL_PAYMENT_ERROR: 'CancelPaymentError',

  AUTOPAY_ENROLL_STARTED: 'autopayEnrollStarted',
  AUTOPAY_ENROLL_COMPLETE: 'autopayEnrollComplete',
  AUTOPAY_ENROLL_CANCELLED: 'autopayEnrollCancelled',
  AUTOPAY_ENROLL_ERROR: 'autopayEnrollError',

  AUTOPAY_CANCEL_STARTED: 'autopayCancelStarted',
  AUTOPAY_CANCEL_COMPLETE: 'autopayCancelComplete',
  AUTOPAY_CANCEL_CANCELLED: 'autopayCancelCancelled',
  AUTOPAY_CANCEL_ERROR: 'autopayCancelError',
  AUTOPAY_ENROLLMENT_DISABLED_MAKE_A_PAYMENT_CLICKED: 'autopayEnrollmentDisabledMakeAPaymentClicked',

  ADD_PAYMENT_ACCT_STARTED: 'addPaymentAcctStarted',
  ADD_PAYMENT_ACCT_COMPLETE: 'addPaymentAcctComplete',
  ADD_PAYMENT_ACCT_CANCELLED: 'addPaymentAcctCancelled',
  ADD_PAYMENT_ACCT_ERROR: 'addPaymentAcctError',

  PAPERLESS_CANCEL: 'paperlessCancel',
  PAPERLESS_CANCEL_COMPLETE: 'paperlessCancelComplete',
  PAPERLESS_CANCEL_ERROR: 'paperlessCancelError',
  PAPERLESS_ENROLL: 'paperlessEnroll',
  PAPERLESS_ENROLL_COMPLETE: 'paperlessEnrollComplete',
  PAPERLESS_ENROLL_ERROR: 'paperlessEnrollError',
  PAPERLESS_CANCELLED: 'paperlessCancel',

  PAYOFF_QUOTE_STARTED: 'payoffQuoteStarted',
  LOAN_PAYOFF_QUOTE_STARTED: 'loanPayoffQuoteStarted',
  LOAN_PAYOFF_QUOTE_ERROR: 'loanPayoffQuoteError',
  LOAN_PAYOFF_QUOTE_CANCELLED: 'loanPayoffQuoteCancelled',
  LOAN_PAYOFF_QUOTE_COMPLETED: 'loanPayoffQuoteCompleted',
  LOAN_PAYOFF_QUOTE_NOT_ELIGIBLE: 'loanPayoffQuoteNotEligible',
  LOAN_PAYOFF_QUOTE_MAIL_ERROR: 'loanPayoffQuoteMailError',
  LOAN_PAYOFF_QUOTE_MAIL_CANCELLED: 'loanPayoffQuoteMailCancelled',
  LOAN_PAYOFF_QUOTE_MAIL_COMPLETED: 'loanPayoffQuoteMailCompleted',

  LEASE_PAYOFF_QUOTE_STARTED: 'leasePayoffQuoteStarted',
  LEASE_PAYOFF_QUOTE_ERROR: 'leasePayoffQuoteError',
  LEASE_PAYOFF_QUOTE_CANCELLED: 'leasePayoffQuoteCancelled',
  LEASE_PAYOFF_QUOTE_COMPLETED: 'leasePayoffQuoteCompleted',
  LEASE_PAYOFF_QUOTE_NOT_ELIGIBLE: 'leasePayoffQuoteNotEligible',

  BALLOON_PAYOFF_QUOTE_STARTED: 'balloonPayoffQuoteStarted',
  BALLOON_PAYOFF_QUOTE_ERROR: 'balloonPayoffQuoteError',
  BALLOON_PAYOFF_QUOTE_CANCELLED: 'balloonPayoffQuoteCancelled',
  BALLOON_PAYOFF_QUOTE_COMPLETED: 'balloonPayoffQuoteCompleted',
  BALLOON_PAYOFF_QUOTE_NOT_ELIGIBLE: 'balloonPayoffQuoteNotEligible',
  BALLOON_PAYOFF_QUOTE_MAIL_ERROR: 'balloonPayoffQuoteMailError',
  BALLOON_PAYOFF_QUOTE_MAIL_CANCELLED: 'balloonPayoffQuoteMailCancelled',
  BALLOON_PAYOFF_QUOTE_MAIL_COMPLETED: 'balloonPayoffQuoteMailCompleted',

  VIEW_STATEMENTS_ACTION: 'viewStatements',
  VIEW_TRANSACTIONS_ACTION: 'viewTransactions',
  DOWNLOAD_STATEMENT_ACTION: 'downloadStatement',

  MESSAGECENETER_LINK: 'messageCenterLink',
  MANAGEACCOUNT_LINK: 'manageAccountLink',
  DUEDATE_CHANGE_LINK: 'dueDateChangeLink',

  // deprecated - use AUTOPAY_ENROLL_COMPLETE
  AUTOPAY_ENROLLED: 'autopayEnrollComplete',
  // deprecated - use AUTOPAY_CANCEL_COMPLETE
  AUTOPAY_CANCELLED: 'autopayCancelComplete',

  OFA_LINK_RENDERED: 'ofaLinkRendered',
  OFA_LINK_CLICKED: 'ofaLinkClicked',

  OPEN_MODAL: 'openModal',
  CLOSE_MODAL: 'closeModal',

  BRAND_ERROR: 'brandError',
  LANGUAGE_ERROR: 'languageError',

  // VSP
  VSP_CTA_SITE_CLICKED: 'VSPCTASiteClicked',
  VSP_CTA_CLOSED: 'VSPCTAClosed',
  VSP_SUCCESSFUL_RESPONSE: 'VSP Successful Response',
  VSP_SUCCESSFUL_EMPTY_RESPONSE: 'VSP Successful Empty Response',

  // Lease transition 6 month
  LEASE_TRANSITION_6_MONTH_CTA_SITE_PRESENTED: 'leaseTransition6MonthCTASitePresented',
  LEASE_TRANSITION_6_MONTH_CTA_SITE_CLICKED: 'leaseTransition6MonthCTASiteClicked',
  LEASE_TRANSITION_6_MONTH_VIEW_YOUR_LEASE_TIMELINE_CTA_CLICKED: 'leaseTransition6MonthViewYourLeaseTimelineCTAClicked',
  LEASE_TRANSITION_6_MONTH_VIEW_YOUR_LEASE_GUIDE_CLICKED: 'leaseTransition6MonthViewYourLeaseGuideClicked',

  // Lease transition 3 month
  LEASE_TRANSITION_3_MONTH_CTA_SITE_PRESENTED: 'leaseTransition3MonthCTASitePresented',
  LEASE_TRANSITION_3_MONTH_CTA_SITE_CLICKED: 'leaseTransition3MonthCTASiteClicked',
  LEASE_TRANSITION_3_MONTH_VIEW_YOUR_LEASE_TIMELINE_CTA_CLICKED: 'leaseTransition3MonthViewYourLeaseTimelineCTAClicked',
  LEASE_TRANSITION_3_MONTH_SCHEDULE_INSPECTION_CLICKED: 'leaseTransition3MonthScheduleInspectionClicked',
  LEASE_TRANSITION_3_MONTH_CONTACT_MY_DEALER_CLICKED: 'leaseTransition3MonthContactMyDealerClicked',
  LEASE_TRANSITION_3_MONTH_VIEW_SAMPLE_INVOICE_CLICKED: 'leaseTransition3MonthViewSampleInvoiceClicked',
  LEASE_TRANSITION_3_MONTH_SEND_SECURE_MESSAGE_CLICKED: 'leaseTransition3MonthSendSecureMessageClicked',
  LEASE_TRANSITION_3_MONTH_VIEW_YOUR_LEASE_GUIDE_CLICKED: 'leaseTransition3MonthViewYourLeaseGuideClicked',
  LEASE_TRANSITION_3_MONTH_SILENCE_REMINDER_CLICKED: 'leaseTransition3MonthSilenceReminderClicked',
  LEASE_TRANSITION_3_MONTH_RENEWAL_JUMP_START_CLICKED: 'leaseTransition3MonthRenewalJumpStartClicked',
  LEASE_TRANSITION_3_MONTH_RENEWAL_GET_STARTED_CLICKED: 'leaseTransition3MonthRenewalGetStartedClicked',
  LEASE_TRANSITION_3_MONTH_RENEWAL_OFA_STARTED: 'leaseTransition3MonthRenewalOfaStarted',
  LEASE_TRANSITION_3_MONTH_RENEWAL_OFA_LEAVE: 'leaseTransition3MonthRenewalOfaLeave',
  LEASE_TRANSITION_3_MONTH_RENEWAL_OFA_STAY: 'leaseTransition3MonthRenewalOfaStay',

  // Close modal for FA.
  CLOSE_FA_MODAL: 'closeFAModal',
  // TitleReleaseBanner
  SHOW_TITLE_RELEASE_BANNER: 'showTitleReleaseBanner',
  TITLE_RELEASE_BANNER_MESSAGE_CENTER_CLICK: 'titleReleaseBannerMessageCenterClick',
  CLOSE_TITLE_RELEASE_BANNER: 'closeTitleReleaseBanner',
  TITLE_RELEASE_BANNER_FAQS_LINK_CLICK: 'titleReleaseBannerFAQSLinkClick',

  // Disaster Banner
  SHOW_DISASTER_BANNER: 'showDisasterBanner',
  DISASTER_BANNER_MESSAGE_CENTER_CLICK: 'disasterBannerMessageCenterClick',
  CLOSE_DISASTER_BANNER: 'closeDisasterBanner',

  // LatePaymentBanner
  SHOW_LATE_PAYMENT_BANNER: 'showLatePaymentBanner',
  LATE_PAYMENT_BANNER_MAKE_PAYMENT_CLICK: 'latePaymentBannerMakePaymentClick',
  CLOSE_LATE_PAYMENT_BANNER: 'closeLatePaymentBanner',

  // PaymentReminderBanner
  SHOW_PAYMENT_REMINDER_BANNER: 'showPaymentReminderBanner',
  PAYMENT_REMINDER_BANNER_MAKE_PAYMENT_CLICK: 'paymentReminderBannerMakePaymentClick',
  CLOSE_REMINDER_PAYMENT_BANNER: 'closePaymentReminderBanner',
  // PaymentConfirmationBanner
  SHOW_PAYMENT_CONFIRMATION_BANNER: 'showPaymentConfirmationBanner',
  PAYMENT_CONFIRMATION_BANNER_TRANSACTIONS_CLICK: 'paymentConfirmationBannerTransactionsClick',
  CLOSE_PAYMENT_CONFIRMATION_BANNER: 'closePaymentConfirmationBanner',
  // LeaseTimeline
  LEASE_TIMELINE_STARTED: 'leaseTimelineStarted',

  // LeaseTransition
  LEASE_TRANSITION_STARTED: 'leaseTransitionStarted',

  // event for the comunication between dashboard zoid and the fs-components modal
  HIDE_MODAL_CLOSE_BUTTON: 'hideModalCloseButton',
  DASHBOARD_MODAL_STATE: 'dashboardModalState',
  DISPATCH_HANDLE_CLOSE_IN_DASHBOARD: 'dispatchHandleCloseInDashboard',

  // event for Account settings
  ACCOUNT_SETTINGS_LOADED: 'accountSettingsLoaded',
  // event for MessageCenter
  MESSAGE_CENTER_COUNT_ERROR: 'messageCenterCountError',
});

export default {
  MessageTypes,
};
