export const ROOT_PATH = '/';
export const MESSAGE_CENTER_PARENT_ROUTE = 'message-center/*';
export const MESSAGE_CENTER_PATH = 'message-center';
export const MESSAGE_CENTER_SECURE_MESSAGE_PATH = 'message-center/inbox/new-message';
export const MESSAGE_CENTER_FAQ_LINK = 'message-center/FAQs';

// path to payment flow
export const MAKE_PAYMENT_PATH = '/payments';
export const MAKE_PAYMENT_AUTOPAY_WARN_PATH = '/payments/autopaywarning';
export const MAKE_PAYMENT_SELECT_PAYMENT_TYPE_PATH = '/payments/selectpaymenttype';
export const MAKE_PAYMENT_VERIFY_PAYMENT_DETAILS_PATH = '/payments/verifypaymentdetails';
export const MAKE_PAYMENT_CONFIRM_PAYMENT_PATH = '/payments/confirmpayment';

// path to autopay flow
export const ALREADY_AUTOPAY_ENROLLED_PATH = 'already-enrolled';
export const AUTOPAY_ENROLL_PATH = '/autopayenroll';
// those below this line are childred from '/autopayenroll' path
export const AUTOPAY_NO_PAYMENT_ACCT_WARN = '/autopayenroll/nopaymentaccount';
export const AUTOPAY_ENROLL_SELECT_PAYMENT_ACCT_PATH = '/autopayenroll/selectpaymentaccount';
export const AUTOPAY_VERIFY_ACCT_PATH = '/autopayenroll/verifyaccount';
export const AUTOPAY_CONFIRM_ENROLL_PATH = '/autopayenroll/confirmenroll';
export const AUTOPAY_CANCEL_ENROLL = '/autopayenroll/cancelenroll';
// those above this line are childred from '/autopayenroll' path

export const PAPERLESS_ENROLL_PATH = '/paperlessenroll';

export const AUTOPAY_CANCEL_PATH = '/autopaycancel';
export const AUTOPAY_CANCEL_WARNING_PATH = 'warning';

// path to payoffquote flow
export const PAYOFF_QUOTE_PATH = '/payoffquote';

// add payment account flows
export const PAYMENT_ACCT_ROOT = '/paymentacct';
// those below this line are childred from `/paymentacct` path
export const PAYMENT_ACCT_ADD_ACCOUNT_NOTIFY = '/paymentacct/addaccountnotify';
export const PAYMENT_ACCT_ADD_ACCOUNT = '/paymentacct/addaccount';
export const PAYMENT_ACCT_ADD_ACCOUNT_CANCEL = '/paymentacct/addacountcancel';
export const PAYMENT_ACCT_ADD_ACCOUNT_CONFIRM = '/paymentacct/addaccountconfirm';
// those above this line are childred from `/paymentacct` path

// path to dashboard without header and footer
export const DASHBOARD_PATH = '/dashboard';
export const DASHBOARD_STATEMENTS_PATH = '/dashboard#statements';
// path to site with header and footer
export const SITE_PATH = '/site';
export const MAKE_PAYMENT_DIALOG_PATH = '#makepayment';
export const MAKE_EOT_PAYMENT_DIALOG_PATH = '#makeeotpayment';
export const CANCEL_PAYMENT_DIALOG_PATH = '#cancelpayment';
export const PAYOFF_QUOTE_DIALOG_PATH = '#payoffquote';
export const LOGIN_PATH = '/login';
export const AUTOPAY_ENROLL_DIALOG_PATH = '#autopayenroll';
export const AUTOPAY_CANCEL_DIALOG_PATH = '#autopaycancel';
export const PAPERLESS_ENROLL_DIALOG_PATH = '#paperlessenroll';
export const EOT_FLOW_DIALOG_PATH = '#eot-elegible';
export const LEASE_TIMELINE_PATH = '#lease-timeline';
export const LEASE_TRANSITION_PATH = '#lease-transition';

export const COMPONENTS_PATH = '/components';

export const SESSION_TIMEOUT_PATH = '/session-timeout';

export const MAINTENANCE_PATH = '/maintenance';
export const INACTIVE_PATH = 'inactive';
export const SITE_INACTIVE_PATH = '/site/inactive';
export const AUTOPAY_INACTIVE_PATH = '/autopay-inactive';
export const MAKE_PAYMENT_INACTIVE_PATH = '/make-payment-inactive';
export const PAPERLESS_ENROLL_INACTIVE_PATH = '/paperless-enroll-inactive';
export const PAYOFF_QUOTE_INACTIVE_PATH = '/payoff-quote-inactive';
export const QUALTRICS_PATH = '/qualtrics';

// this path is only used when the dashboard is running as a zoid or standalone componennt
export const ACCOUNT_SETTINGS_PATH = '/account';
export const ACCOUNT_SETTINGS_DUE_DATE_CHANGE_PATH = '/paymentOptions';
export const MESSAGE_CENTER_INBOX_PATH = 'message-center/inbox';
